import React, { Component} from "react";
import ReactDOM from 'react-dom';
// import $, { event } from 'jquery';
import {
    BrowserRouter as Router,
    Link,
    // Route,
    // Routes,
    // useParams,
  } from "react-router-dom";
  
// import Pagination from "@material-ui/lab/Pagination";
import "../../../assets/css/toolSupport.css";
import "../../../assets/css/PTS/order.css";

import servicesPTS from "../../../components/PTS/ServicesPTS/ServicesPTS";

const BASE_URL = "http://192.168.3.26:8080"
const APP_URL_TEMP = "/static/pto/";



class EditOrder extends Component {
    
    constructor(props) {
        
        super(props);

        this.getOrderBannerId = this.getOrderBannerId.bind(this);
        this.getUpdateBannerId = this.getUpdateBannerId.bind(this);

        this.handleDataType = this.handleDataType.bind(this);
        this.handleDataSize = this.handleDataSize.bind(this);
        this.handleDataImage = this.handleDataImage.bind(this);
        this.handleDataNamaToko = this.handleDataNamaToko.bind(this);
        this.handleDataTaglineToko = this.handleDataTaglineToko.bind(this);
        this.handleDataKontakToko = this.handleDataKontakToko.bind(this);
        this.handleDataKirimNama = this.handleDataKirimNama.bind(this);
        this.handleDataKirimAlamat = this.handleDataKirimAlamat.bind(this);
        this.handleDataKirimPhone = this.handleDataKirimPhone.bind(this);

        this.handleCheckIsName = this.handleCheckIsName.bind(this);
        this.handleCheckIsTagline = this.handleCheckIsTagline.bind(this);
        this.handleCheckIsContact = this.handleCheckIsContact.bind(this);
        // this.getDataId = this.getDataId.bind(this);
        this.state = {
            

            id : props.match.params.id,
            listOrderId: [],
            value: "",

            updateOrderBanner:{
                dataProId: "",
                type: "",
                size: "",
                image: "",
                imagePreview: "",
                display_name: "",
                display_tagline: "",
                display_contact: "",
                
                isName: 0,
                defaultCheckName: true,
                isTagline: 1,
                defaultCheckTagline: true,
                isContact: 1,
                defaultCheckContact: true,


                recipient: "",
                delivery_address: "",
                phone: "",
                
            },
            // listDataOrder: "",
            // listUpdateOrderId:
        };
        
    }
    componentDidMount() {  
        
        
        this.getOrderBannerId();
        
        // this.handleDataType();
    }

    

    getOrderBannerId(){
        var id = this.state.id;
        servicesPTS.getSearchOrderBannerId(id)
            .then((response) =>{
                // console.log(response);
                this.setState({
                    listOrderId: response.data,

                    updateOrderBanner:{
                        type: response.data[0].type,
                        size: response.data[0].size,
                        image: response.data[0].image,
                        display_name: response[0].data.display_name,
                        display_tagline: response.data[0].display_tagline,
                        display_contactw: response.data[0].display_contact,
                        isName: response.data[0].isName,
                        isTagline: response.data[0].isTagline,
                        isContact: response.data[0].isContact,
                        recipient: response.data[0].recipient,
                        delivery_address: response.data[0].delivery_address,
                        phone: response.data[0].phone,
                    }
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleDataType(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                type: tar,
            },
        }));
    }
    handleDataSize(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                size: tar,
            },
        }));
    }
    handleDataImage(e){
        const img = e.target.files[0];
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                image: img,
                // imagePreview: URL.createObjectURL(img) 
            }
        }))
    }
    // funcImage(url,index){
    //     if(this.updateOrderBanner.image == ""){
            
    //     }
    // }
    handleDataNamaToko(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                display_name: tar,
            },
        }));
    }
    handleDataTaglineToko(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                display_tagline: tar,
            },
        }));
    }
    handleDataKontakToko(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                display_contact: tar,
            },
        }));
    }

    // handleDataDikirimResi(e){
    //     const tar = e.target.value;
    //     this.setState((prevState) => ({
    //         updateOrderBanner:{
    //             ...prevState.updateOrderBanner,
    //             display_contact: tar,
    //         },
    //     }));
    // }
    handleDataKirimNama(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                recipient: tar,
            },
        }));
    }
    handleDataKirimAlamat(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                delivery_address: tar,
            },
        }));
    }
    handleDataKirimPhone(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            updateOrderBanner:{
                ...prevState.updateOrderBanner,
                phone: tar,
            },
        }));
    }

    handleCheckIsName(e){
        const defaultCheckContact = e.target.checked;
        if(defaultCheckContact === true){
            this.setState(
                {
                    isName: Number(1),
                },
                // ()=>{console.log(this.state.isName);}
            )
        }else{
            this.setState(
                {
                    isName: Number(0),
                    display_name: "",
                },
                // ()=>{console.log(this.state.isName);}
            )
        }
    }
    handleCheckIsTagline(e){
        const defaultCheckContact = e.target.checked;
        if(defaultCheckContact === true){
            this.setState(
                {
                    isTagline: Number(1),
                },
                ()=>{console.log(this.state.isTagline);}
            )
        }else{
            this.setState(
                {
                    isTagline: Number(0),
                },
                ()=>{console.log(this.state.isTagline);}
            )
        }
    }
    handleCheckIsContact(e){
        const defaultCheckContact = e.target.checked;
        if(defaultCheckContact === true){
            this.setState(
                {

                    isContact: Number(1),
                },
                // ()=>{console.log(this.state.isContact);}
            )
        }else{
            this.setState(
                {
                    isContact: Number(0),
                },
                // ()=>{console.log(this.state.isContact);}
            )
        }
    }


    onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    configurationImage(w, h){
        const width = Number(w);
        const height = Number(h);
        var text =  'Bentuk Gambar';
        if(width === height){
            return(
                <div className="config fontFamily font-size14 text-center" 
                    style={{
                        width: 100, 
                        height: 100, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }else if(width <= height){
            return(
                <div className="config1 fontFamily font-size14 text-center" 
                    style={{
                        width: 100, 
                        height: 150, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }else if(width >= height){
            return(
                <div className="config2 fontFamily font-size14 text-center" 
                    style={{
                        width: 150, 
                        height: 100, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }
    }

    getUpdateBannerId(e){
        var id = this.state.id;
        var data = {
            type: this.state.updateOrderBanner.type,
            size: this.state.updateOrderBanner.size,
            files: this.state.updateOrderBanner.image,
            
            display_name: this.state.updateOrderBanner.display_name,
            display_tagline: this.state.updateOrderBanner.display_tagline,
            display_contact: this.state.updateOrderBanner.display_contact,
            isName: "",
            isTagline: "",
            isContact: "",
            
            recipient: this.state.updateOrderBanner.recipient,
            delivery_address: this.state.updateOrderBanner.delivery_address,
            phone: this.state.updateOrderBanner.phone,
        }
        servicesPTS.updateOrderBannerId(id, data)
        .then(response => {
            this.setState({
                // dataProId: response.data.dataProId,
                type: response.data.type,
                size: response.data.size,
                files: response.data.image,
                
                display_name: response.data.display_name,
                display_tagline: response.data.display_tagline,
                display_contact: response.data.display_contact,
                isName: response.data.isName,
                isTagline: response.data.isTagline,
                isContact: response.data.isContact,
                
                delivery_address: response.data.delivery_address,
                recipient: response.data.recipient,
                phone: response.data.phone,
            });
        })
        .catch(e => {
            console.log(e);
        });
        e.preventDefault();
    }

    
    render() {
        
        const {
            listOrderId,
        } = this.state;

        
        const urlImage = BASE_URL+APP_URL_TEMP;
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <Link to={{pathname:'/tool-support/order'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span className="span-breadcrumb pl-15" id="satu">Order Banner</span>
                            </Link>
                            
                        </div>
                    </div>
                    <div className="row pl-40 color-1 fontFamily">
                        <div className="col-lg-12 pl-0" id="title-h1">
                            
                                {listOrderId.map((index) => (
                                    <h1>Edit Banner <span className="namaToko">{index.toko}</span></h1>
                                ))}
                            
                            
                        </div>
                        <div className="col-lg-12">
                            {listOrderId.map((index) => (
                            <form method="POST" encType="multipart/form-data" ref={form => this.form = form}>
                            
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="type">Tipe Pesanan</label>
                                        </div>
                                        <div className="col-lg-10 d-flex">
                                            <input 
                                                type="text" 
                                                className="button-typeText" 
                                                id="formUkuran2" 
                                                name="type"
                                                defaultValue={index.type}
                                                // placeholder={}
                                                onChange={this.handleDataType} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="size">Ukuran Shop Banner</label>
                                        </div>
                                        <div className="col-lg-10 d-flex">
                                            <input 
                                                type="text" 
                                                className="button-typeText" 
                                                id="formUkuran2" 
                                                name="size"  
                                                defaultValue={index.size} 
                                                onChange={this.handleDataSize} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Foto</label>
                                        </div>
                                        <div className="col-lg-5">
                                            <div id="uploadFoto" className="d-grid mx-auto">
                                                <div className="padding-h10" id="previewFoto">
                                                    {/* Preview */}
                                                    <div className="">
                                                        <img 
                                                            className="col-lg-12 col-xs-12 img-responsive" 
                                                            src={urlImage+index.image}
                                                        />
                                                        {/* <input
                                                            className="col-lg-3 col-xs-12"
                                                            type="file" 
                                                            id="my_file" 
                                                            name="image"
                                                            style={{color:"rgba(0, 0, 0, 0)"}}
                                                            onChange={this.handleDataImage} 
                                                        /> */}
                                                        {/* <span className="col-lg-9 col-xs-12">{index.image}</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row" id="teksBanner">
                                        <div className="col-lg-12 pl-0">
                                            <h3>Teks di Shop Banner</h3>
                                        </div>
                                        <div className="container-fluid">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama Toko</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            type="checkbox" 
                                                            id="topping" 
                                                            name="isName" 
                                                            value="" 
                                                            onChange={this.handleCheckIsName}
                                                            defaultChecked={this.state.defaultCheckName}
                                                        />
                                                        <input 
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="display_name"
                                                             
                                                            defaultValue={this.state.isName === 0 ?  index.display_name : '' }
                                                            onChange={this.handleDataNamaToko}
                                                            // disabled={this.state.isName === 0 ? true : false}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Tagline</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            type="checkbox" 
                                                            id="topping" 
                                                            name="isTagline" 
                                                            value="" 
                                                        />
                                                        <input 
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="display_tagline"  
                                                            defaultValue={index.display_tagline}
                                                            onChange={this.handleDataTaglineToko}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak/Alamat</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            type="checkbox" 
                                                            id="topping" 
                                                            name="isContact" 
                                                            value=""
                                                            // checked=""
                                                            onChange={this.handleCheckIsContact}
                                                            defaultChecked={this.state.defaultCheckContact}
                                                        />
                                                        <textarea 
                                                            rows="4"
                                                            className="button-typeTextarea" 
                                                            name="display_contact"  
                                                            defaultValue={index.display_contact} 
                                                            onChange={this.handleDataKontakToko}>
                                                        </textarea>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Dikirim Kepada</h3>
                                            </div>
                                            <div className="container-fluid">
                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="sendResi"  
                                                                // value={this.state.targetSendResi} 
                                                                // onChange={this.targetSendResi} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="recipient"  
                                                                defaultValue={index.recipient}
                                                                onChange={this.handleDataKirimNama} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="delivery_address"  
                                                                defaultValue={index.delivery_address}
                                                                onChange={this.handleDataKirimAlamat} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 font-w100">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                onKeyPress={this.onKeyNumber} 
                                                                name="phone"  
                                                                defaultValue={index.phone} 
                                                                onChange={this.handleDataKirimPhone} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                    <div className="col-lg-12">
                                        <button onClick={(e) => this.getUpdateBannerId(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Edit Shop Banner</button>
                                    </div>
                            
                            </form>
                            ))}
                                
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default EditOrder;