import Application from "./views/Application";
import Dashboard from "./views/Dashboard";
import ToolSupport from "./views/ToolSupport";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
  },
  {
    path: "/application",
    name: "List Toko/Seatmaker",
    icon: "pe-7s-id",
    component: Application,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "pe-7s-users",
  },
  {
    path: "/tool-support",
    name: "Tool Support",
    component: ToolSupport,
  },
  


];

export default dashboardRoutes;
