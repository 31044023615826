import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Link, useHistory } from 'react-router-dom';
import $, { event } from 'jquery';

import '../assets/css/PTS/globalPTS.css';
import '../assets/css/PTS/login.css';
import "../assets/css/toolSupport.css";
import userService from "../components/PTS/ServicesPTS/user.service";

const PTSLogin = () =>{
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const history = useHistory();

    useEffect(() =>{
        document.body.style.backgroundColor = "#292d32";

        const item = localStorage.getItem('userPTS');
        if(item){
            const ada = JSON.parse(item).data;
            if(ada.length){
                history.push('/tool-support');
                window.location.reload();
            }else{
                window.location.reload();
            }
        }
    }, []);

    const onChangeUsername = (e) => {
        setUsername(e.target.value);
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const Auth = (e) => {
        e.preventDefault();
        // console.log('pengguna',username+' & '+password);
        userService.login(username, password)
        .then((response) => {
            if(!response.data.data.length){
                return setMessage("Username atau Password anda salah");
            }else{
                if(response.data.data[0].remember_token) {
                    localStorage.setItem("userPTS", JSON.stringify(response.data));
                }
                history.push("/tool-support");
                window.location.reload();
                return response.data
            }
        })
        .catch((e) => {
            if(e.response){
                setMessage(e.response.data.message);
            }
        })
    }
    
    return(
        <div className="container-fluid">
                 <div className="row">
                     <section className="section-layout" id="login">
                         <div className="container">
                             {/* <div className="box-middle"> */}
                                 <div className="row">
                                     <div className="col-lg-12 ">
                                         <h2 className="h2login text-center">Promotional Tools Support</h2>
                                     </div>
                                 </div>
                                 <div className="row">
                                     <div className="box mt-15">
                                         <div className="col-lg-4 col-xs-10 box-login">
                                             <div className="row">
                                                 <div className="col-lg-12">
                                                     <span className="writeLogin fontFamily">Log in</span>
                                                 </div>
                                                 <div className="col-lg-12">
                                                     <div>
                                                         <form onSubmit={Auth}>
                                                            <div 
                                                                id="messageWarning" 
                                                                className="col-lg-12 text-center text-danger"
                                                            >
                                                                {message}
                                                            </div>
                                                            <input
                                                                 id=""
                                                                 className="col-lg-12 col-xs-12 mt-25 form-input"
                                                                 name="username"
                                                                 type="text"
                                                                 value={username}
                                                                 onChange={onChangeUsername}
                                                                 placeholder="Input username..."
                                                             >
                                                             </input>
                                                             <input
                                                                 id=""
                                                                 className="col-lg-12 col-xs-12 mt-15 form-input"
                                                                 name="password"
                                                                 type="password"
                                                                 value={password}
                                                                 onChange={onChangePassword}
                                                                 placeholder="&#9679; &#9679; &#9679; &#9679; &#9679; &#9679;"
                                                             >
                                                             </input>
                                                             <button className="col-lg-12 col-xs-12 mt-20" type="submit">Log in</button>
                                                         </form>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             {/* </div> */}
                         </div>
                     </section>
                 </div>
             </div>
    );
}
export default PTSLogin;

// class PTSLogin extends Component {   
//     constructor(props) {
//         super(props);
//         // this.retrieveLastApplication = this.retrieveLastApplication.bind(this);
//         this.onChangeUsername = this.onChangeUsername.bind(this);
//         this.state = {
//             isAuth: false,
//             dataUser:[
//                 {
//                     username: 'admin',
//                     pass: 'admin',
//                     role: 'admin'
//                 },
//                 {
//                     username: 'user1',
//                     pass: 'user1',
//                     role: 'sales'
//                 },
//                 {
//                     username: 'user2',
//                     pass: 'user2',
//                     role: 'dcd'
//                 },
//                 {
//                     username: 'user3',
//                     pass: 'user3',
//                     role: 'cetak'
//                 },
//             ],
//         };
//     }
    
//     componentDidMount() {
//         document.body.style.backgroundColor = "#292d32";
//     }

//     onChangeUsername(e) {
//         this.setState({
//             username: e.target.value
//         });
//     }
//     onChangePass(e) {
//         this.setState({
//             pass: e.target.value
//         });
//     }

//     submitLogin(e){
//         e.preventDefault();
        
//     }
    

//     render(){
//         const {
//         } = this.state;
//         return(
//             <div className="container-fluid">
//                 <div className="row">
//                     <section className="section-layout" id="login">
//                         <div className="container">
//                             {/* <div className="box-middle"> */}
//                                 <div className="row">
//                                     <div className="col-lg-12 ">
//                                         <h2 className="h2login text-center">Promotional Tools Support</h2>
//                                     </div>
//                                 </div>
//                                 <div className="row">
//                                     <div className="box mt-15">
//                                         <div className="col-lg-4 col-xs-9 box-login">
//                                             <div className="row">
//                                                 <div className="col-lg-12">
//                                                     <span className="writeLogin fontFamily">Log in</span>
//                                                 </div>
//                                                 <div className="col-lg-12">
//                                                     <div>
//                                                         <form>
//                                                             <input
//                                                                 id=""
//                                                                 className="col-lg-12 mt-25 form-input"
//                                                                 name="username"
//                                                                 type="text"
//                                                                 value={this.state.dataUser.username}
//                                                                 onChange={this.onChangeUsername}
//                                                                 placeholder="Input username..."
//                                                             >
//                                                             </input>
//                                                             <input
//                                                                 id=""
//                                                                 className="col-lg-12 mt-15 form-input"
//                                                                 name="password"
//                                                                 type="password"
//                                                                 value={this.state.dataUser.pass}
//                                                                 onChange={``}
//                                                                 placeholder="Input Password..."
//                                                             >
//                                                             </input>
//                                                             <button className="col-lg-12 col-xs-12 mt-20" type="submit">Log in</button>
//                                                         </form>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             {/* </div> */}
//                         </div>
//                     </section>
//                 </div>
//             </div>
//         );
//     }
// }
// export default PTSLogin;