import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $, { event } from 'jquery';
import Pagination from "@material-ui/lab/Pagination";
import { BrowserRouter as Router, Switch, Route, Link, BrowserRouter } from 'react-router-dom';

import "../../../assets/css/toolSupport.css";
import "../../../assets/css/PTS/order.css";



import servicesPTS from "../../../components/PTS/ServicesPTS/ServicesPTS";
import { Button } from "react-bootstrap/lib/InputGroup";

class Order extends Component {
    constructor(props) {
        super(props);        
        this.getAllOrderBanner = this.getAllOrderBanner.bind(this);
        // this.deleteOrderBanner = this.deleteOrderBanner.bind(this);
        this.state = {
            orderData: [],
            deleteOrderId: "",
        };
    }
    componentDidMount() {  
        this.getAllOrderBanner();
        // this.defaultCheckContact();
    }

    getAllOrderBanner(){
        servicesPTS.getAllOrderPts()
            .then((response) =>{
                this.setState({
                    orderData:response.data,
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    time_stamp(time){
        var time_stamp = new Date(time);
        var tanggal = ('0' + time_stamp.getUTCDate()).slice(-2);
        var bulan = ('0' + (time_stamp.getUTCMonth() + 1)).slice(-2);
        var tahun = time_stamp.getUTCFullYear();
        return(
            <>
                {tanggal+'/'+bulan+'/'+tahun}
            </>
        )
    }

    deleteOrderBanner(id) {
        servicesPTS.deleteOrderBannerId(id)
          .then((response) => {
            this.setState({ deleteOrderId: true });
            window.location.reload();
          })
          .catch((e) => {
            console.log(e);
          });
    }

    handleCheckbox(data, name){
        if(data == 0){
            return 'Pending';
        }else{
            return(
                <div id="checklist">
                    <input class="checkbox" type="checkbox" id="" name={name} value="" checked disabled/>
                </div>
            );
        }
    }
    
    render() {
        const {
            orderData,
        } = this.state;
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <span className="span-breadcrumb pl-15">Order Banner</span>
                        </div>
                    </div>
                    <div className="row pl-40 color-1 fontFamily">

                        <div className="col-lg-6 pl-0" id="title-h1">
                            <h1>Pemesanan Order Banner</h1>
                        </div>
                        <div className="col-lg-6" id="title-h1">
                            <Link to={{pathname:'/tool-support/input-order'}} className="col-lg-5 col-xs-12 btn buttonTambah float-right"><span className="badge-plug">+</span>Pesan Shop Banner</Link>
                        </div>
                        <div className="col-lg-12">
                            <table className="table table-striped table-hover">
                                <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Nama Seatmaker</th>
                                    <th>Type</th>
                                    <th>Size</th>
                                    <th>Alamat Pengiriman</th>
                                    <th>Recipient</th>
                                    <th>No HP</th>
                                    <th>Pengajuan</th>
                                    <th>Status</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orderData.dataOrder &&
                                    orderData.dataOrder.map((item, i) => (
                                        <tr>
                                            <td key={item}>{i+1}</td>
                                            <td>{item.toko}</td>
                                            <td>{item.type}</td>
                                            <td>{item.size}</td>
                                            <td>{item.delivery_address}</td>
                                            <td>{item.recipient}</td>
                                            <td>{item.phone}</td>
                                            <td>{this.time_stamp(item.created_at)}</td>
                                            <td>{this.handleCheckbox(item.status, 'status')}</td>
                                            <td>
                                                <div className="col-lg-12 margin-h3">
                                                    <Link to={`/tool-support/order/${item.id}`}
                                                        className="btn btn-success btn-fill col-lg-12">
                                                            Edit
                                                    </Link>
                                                </div>
                                                <div className="col-lg-12 margin-h3">

                                                <a
                                                    className="btn btn-danger btn-fill col-lg-12"
                                                    href="#"
                                                    onClick={() => {
                                                        if (
                                                        window.confirm(
                                                            "Apa Kamu Yakin Ingin Menghapus Data Order Banner ini?"
                                                        )
                                                        )
                                                        this.deleteOrderBanner(item.id);
                                                    }}
                                                    >
                                                    Hapus
                                                </a>
                                                    {/* <button onClick={this.deleteOrderBanner(item.id)} className="btn btn-danger btn-fill col-lg-12">Hapus</button> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        
                        


                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default Order;