import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import ServicesPTS from "../../../Services/ServicesPTS";
import SeatmakerDataService from "../../../../../services/smaker.service"

const CatalogInput = () => {
    const viewCatalog = process.env.REACT_APP_APP_URL+'tool-support/catalog';
    const history = useHistory();
    const [width, setWidth] = useState(window.innerWidth);

    const [dataMS, setDataMS] = useState([]);
    const [insertMS, setInsertMS] = useState({});
    const [id, setId] = useState(0);
    const [dataProId, setDataProId] = useState("");
    const [sendTo, setSendTo] = useState("");
    const [products, setProducts] = useState([
        { "varian": "Camaro", "total": 0, "color": "", },
        { "varian": "Camaro Fiesta", "total": 0, "color": "", },
        { "varian": "Giorgio", "total": 0, "color": "", },
        { "varian": "New Superior", "total": 0, "color": "", },
        { "varian": "Picasso", "total": 0, "color": "", },
        { "varian": "Premium Carrera", "total": 0, "color": "", },
        { "varian": "Rider Cruiser", "total": 0, "color": "", },
        { "varian": "Catalog Book", "total": 0, "color": "", },
    ]);
    const [noReceipt, setNoReceipt] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [varian, setVarian] = useState("");
    const [size, setSize] = useState("");
    const [total, setTotal] = useState(0);
    const [color, setColor] = useState("");
    const [penjumlahan, setPenjumlahan] = useState(0);
    const [isLoading, setIsloading] = useState(false);
    
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [input, setInput] = useState("");

    const [tempAddress, setTempAddress] = useState("");
    

    useEffect(() =>{
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["toko"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    }



    const retrieveSeatmakers = async (item) => {
        var temp = [];
        const items  = item;
        setIsloading(true);
        if(items.length >= 3){
            const params = getRequestParams(items, page, pageSize);
            const response = await SeatmakerDataService.getAll(params);
            const itemTotal = await items ? response.data.totalItems : '';
            const paramsEks = getRequestParams(items, page, itemTotal);
            const responsFin = await (await SeatmakerDataService.getAll(paramsEks)).data.seatmakers;
            for(let i=0; i < responsFin.length; i++){
                temp.push({
                    id: responsFin[i].id,
                    toko: responsFin[i].toko
                });
            }
            return temp;
        }else{
            setTempAddress("");
            setName("");
            setAddress("");
            setPhone("");
            return temp;
        }
    }
    

    const onChange = async(e) => {
        e.persist();
        const input = e.currentTarget.value;
        let todoList = await retrieveSeatmakers(input);
        let listToko = todoList.map((data) => data.id+' - '+data.toko);
        
        const suggestions  = listToko;
        
        console.log('sebelum filter',suggestions)
        const newFilteredSuggestions = suggestions.filter(
            suggestion =>
            suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
        );
        console.log('filter ',newFilteredSuggestions);
        setIsloading(false);
        setActive(0);
        setFiltered(newFilteredSuggestions);
        setIsShow(true);
        console.log('uian',newFilteredSuggestions);
        setInput(input);
        
    };

    const filterNameShop = async(namaToko) => {
        var temp = namaToko;
        var result = temp.substring(0,temp.indexOf(" - "));
        
        var responProfile = await(await SeatmakerDataService.getProfile(result)).data;

        setTempAddress(responProfile);
        setName(responProfile.contact);
        setAddress(responProfile.alamat);
        setPhone(responProfile.phone);
        
        
    }

    const onClick = e => {
        
        setActive(0);
        setFiltered([]);
        setIsShow(false);
        setInput(e.currentTarget.innerText);
        filterNameShop(e.currentTarget.innerText);
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) { // enter key
          setActive(0);
          setIsShow(false);
          setInput(filtered[active]);
          filterNameShop(filtered[active]);
          e.preventDefault();
        }
        else if (e.keyCode === 38) { // up arrow
          return (active === 0) ? null : setActive(active - 1);
        }
        else if (e.keyCode === 40) { // down arrow
          return (active - 1 === filtered.length) ? null : setActive(active + 1);
        }
    };

    const renderAutocomplete = () => {
        if (isShow && input) {
            // console.log('render',input);
          if (filtered.length) {
            return (
              <ul className="autocomplete">
                {
                    isLoading ?
                        <>
                            <li className="lazyColor">Loading...</li>
                            <li className="lazyColor">...</li>
                            <li className="lazyColor">...</li>
                        </>
                    :
                    filtered.map((suggestion, index) => {
                        let className;
                        if (index === active) {
                            className = "active";
                        }
                        return (
                            <li className={className} key={suggestion} onClick={onClick}>
                            {suggestion}
                            </li>
                        );
                    })
                }
              </ul>
            );
          } else {
            return (
              <div className="no-autocomplete">
                <em>Not found</em>
              </div>
            );
          }
        }
        return <></>;
    }


    const onChangeProducts = (e) => {
        const product = products;
        const val = e.target.value;
        var ids = e.target.id;
        if(ids === "Catalog Book"){
            product.push(
                {
                    varian: ids,
                    total: 0,
                    color: val,
                },
            )
        }else{
            product.push(
                {
                    varian: ids,
                    total: val,
                    color: "",
                },
            )
        }
        
        // this.funcAddition();
    }

    const targetPhone = (e) =>{
        e.preventDefault();
        setPhone(e.target.value);
    }
    const targetAddress = (e) => {
        setAddress(e.target.value);
    }
    const targetName = (e) => {
        setName(e.target.value);
    }
    const targetNoReceipt = (e) => {
        setNoReceipt(e.target.value);
    }

    const onKeyNumber = (event) =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const funcAddition = () => {
        const temp_products = [...new Map(products.map(o => [o.varian, o])).values()];
        let jumlahnya = 0;
        for(let i = 0; i < temp_products.length; i++){
            jumlahnya += Number(temp_products[i].total);
        }
        setPenjumlahan(jumlahnya);
    }

    const saveCatalog = (e) =>{
        e.preventDefault();
        const product = [...new Map(products.map(o => [o.varian, o])).values()]
        var inputToko = input.split(' ');

        var data = {
            dataProId : inputToko[0],
            // dataProId : 800,
            sendTo : sendTo,
            noReceipt : noReceipt,
            name : name,
            address : address,
            phone : phone,
            product: product,
        }
        ServicesPTS.createCatalog(data)
        .then(response =>{
            setInsertMS({
                dataProId: response.data.dataProId,
                sendTo: response.data.sendTo,
                noReceipt: response.data.noReceipt,
                name: response.data.name,
                address: response.data.address,
                phone: response.data.phone,
                product: response.data.product
            });


            if(response.data.success == true){
                history.push({
                    pathname: '/tool-support/catalog',
                    state:{
                        kirim: 'data berhasil disimpan',
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
        });
    }

    return(
        <div className="row">
            <div className="col-lg-12 col-md-12 pb-10 box-shadow-1 border-radius-10 mt-pto-1 border-left-20 mb-17per">
                <div className="" id="breadcrumb">
                    <div className="padding-h15">
                        <a href={viewCatalog}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className="span-breadcrumb pl-15">Catalog</span>
                        </a>
                        {/* <span className="span-breadcrumb pl-15">/ Input Master Sample </span> */}
                    </div>
                </div>
                <div className={`${width <= 480 ? 'pl-15':'pl-40'} row color-1 fontFamily mb-10`}>
                    <div className="col-lg-12 pl-0" id="title-h1">
                        <h1>Input Catalog</h1>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                        <div className="col-lg-12 pl-0">
                            <h3><strong>Request Catalog</strong></h3>
                        </div>
                            <form className="col-lg-12" method="POST" encType="multipart/form-data" ref={form => form = form}>
                                
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="qty">Nama Toko <span style={{color:'red'}}>*(3 Huruf)</span></label>
                                        </div>
                                        <div className="col-lg-10 d-flex">
                                            <div className="bg-jumlah  col-lg-6 col-md-6 col-xs-12" for="toko">
                                                <input
                                                    autoComplete="off"
                                                    className="button-typeText text-effect col-lg-12 col-xs-12"
                                                    type="text"
                                                    id="Seatmaker"
                                                    onChange={(e) => { onChange(e)}}
                                                    onKeyDown={onKeyDown}
                                                    value={input}
                                                    placeholder="Nama Toko / Seatmaker"
                                                />
                                                {renderAutocomplete()}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="Camaro">Camaro</label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="jumlahCamaro">
                                                <label for="Camaro" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number" 
                                                    min={0}
                                                    defaultValue={products.total ? products.total : 0} 
                                                    onChange={onChangeProducts} 
                                                    id="Camaro" 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="Camaro Fiesta">Camaro Fiesta</label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="Camaro Fiesta">
                                                <label for="Camaro Fiesta" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number"
                                                    min={0}
                                                    defaultValue={products.total ? products.total : 0} 
                                                    onChange={onChangeProducts} 
                                                    id="Camaro Fiesta" 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                />
                                                {/* <span>
                                                    <input 
                                                        type="checkbox" 
                                                        id="vehicle2" 
                                                        name="vehicle2" 
                                                        value="checklistCamaro"
                                                    />
                                                </span> */}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="Giorgio">Giorgio</label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="Giorgio">
                                                <label for="Giorgio" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number" 
                                                    min={0}
                                                    id="Giorgio" 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                    defaultValue={products.total ? products.total : 0} 
                                                    onChange={onChangeProducts}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="New Superior">New Superior</label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="New Superior">
                                                <label for="New Superior" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number" 
                                                    min={0} 
                                                    id="New Superior" 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                    defaultValue={products.total ? products.total : 0} 
                                                    onChange={onChangeProducts}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="Picasso">Picasso</label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="Picasso">
                                                <label for="Picasso" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number" 
                                                    min={0}
                                                    id="Picasso" 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                    defaultValue={products.total ? products.total : 0} 
                                                    onChange={onChangeProducts}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="Premium Carrera">Premium Carrera</label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="Premium Carrera">
                                                <label for="Premium Carrera" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number" 
                                                    min={0}
                                                    id="Premium Carrera" 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                    defaultValue={products.total ? products.total : 0} 
                                                    onChange={onChangeProducts}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="Rider Cruiser">Riders Cruiser</label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="Rider Cruiser">
                                                <label for="Rider Cruiser" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number" 
                                                    min={0}
                                                    id="Rider Cruiser" 
                                                    defaultValue={products.total ? products.total : 0} 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                    onChange={onChangeProducts}
                                                    
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="Catalog Book">Catalog Book (mix)</label>
                                        </div>
                                        <div className="col-lg-10 d-flex">
                                            <div className="bg-jumlah d-flex" for="Catalog Book">
                                                <textarea 
                                                    id="Catalog Book"  
                                                    className="" 
                                                    rows="4" 
                                                    cols="29"
                                                    value={products.color}
                                                    onChange={onChangeProducts}
                                                    placeholder="Catalog Campuran / Bundle"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                {/* <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily">Jumlah Total</label>
                                        </div>
                                        <div className="col-lg-10 d-flex pl-0">
                                            <input 
                                                value={this.state.penjumlahan}
                                                disabled 
                                                id="jumlahcustom" 
                                                className="ml-15 button-typeText text-effect"
                                            />
                                        </div>
                                    </div>
                                </div> */}

                                <div class="form-group">
                                    <div className="row" id="teksBanner">
                                        <div className="col-lg-12 pl-0">
                                            <h3>Dikirim Kepada</h3>
                                        </div>
                                        <div className="container-fluid">
                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="noReceipt"  
                                                            value={noReceipt}
                                                            onChange={targetNoReceipt} 
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            key={tempAddress.contact} 
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="name"  
                                                            defaultValue={tempAddress.contact} 
                                                            onChange={targetName} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            key={tempAddress.alamat} 
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="address"  
                                                            defaultValue={tempAddress.alamat}
                                                            onChange={targetAddress} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0 font-w100">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input
                                                            maxLength={12} 
                                                            key={tempAddress.phone} 
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            onKeyPress={onKeyNumber} 
                                                            name="phone"
                                                            defaultValue={tempAddress.phone} 
                                                            onChange={e => targetPhone(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <button onClick={(e) => saveCatalog(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Kirim Permintaan</button>
                                </div>
                            </form>
                        </div>

                            

                    </div>
                </div>
                
            </div>
            
        </div>
    );
}
export default CatalogInput;