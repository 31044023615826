import React, { useState, useEffect } from "react";
import ServicesPTS from "../../Services/ServicesPTS";
import ComponentToPrintDetil from "./ComponentToPrintDetil";
import './toPrint.css';

const ComponentPrintMasterSample = ( { propsData } ) => {
    const tempProps  = propsData;

    const [itemPrint, setItemPrint] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchToPrint(tempProps);
    },[tempProps]);

    const fetchToPrint = (data) =>{
        setIsLoading(true);
        ServicesPTS.getDetailMasterSample(data)
            .then((response) => {
                setItemPrint(response.data[0]);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    
    return(
        <>
            
            <ComponentToPrintDetil
                item={
                    <>
                        <div className="print- col-lg-7 col-xs-7 fontFamily">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="pl-0 col-lg-6 col-xs-6">
                                    <img
                                        src="https://mbtech.info/asset/img/logo/MBtech.png"
                                        alt="logo mbtech"
                                        width="125"
                                    />
                                    </div>
                                    <div className="col-lg-6 col-xs-6 pr-0 text-right font-w600 font-size25 fontFamily pt-25" style={{color:'#222222'}}>
                                        <div className="col-lg-12">Resi</div>
                                        <div className="col-lg-12 col-xs-12 float-right borderBottom" style={{fontSize:'small'}}> &nbsp; </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="dotted"></hr>
                            <div className="print-body">
                                <div className="col-lg-2 col-xs-2 pl-0 fontFamily" style={{color:'#717171'}}>
                                    Kepada
                                </div>
                                <div className="col-lg-10 col-xs-10 pr-0 fontFamily">
                                    <div className="item-toko font-w600 font-size16 fontFamily">
                                    {`${itemPrint.name} (${itemPrint.toko})`}
                                    </div>
                                    <div className="item-alamat fontFamily">
                                    {itemPrint.address}
                                    {/* Jalan Professo Doktor Satrio, Kecamatan Setiabudi, 12940, Setiabudi, Kota Administrasi Jakarta Selatan, DKI Jakarta, 12920 */}
                                    </div>
                                    <div className="item-contact fontFamily">
                                    {/* +6281293981734 */}
                                    {itemPrint.phone}
                                    </div>
                                </div>

                                <div className="col-lg-2 col-xs-2 pt-20 pl-0 fontFamily" style={{color:'#717171'}}>
                                    Dari
                                </div>
                                <div className="col-lg-10 col-xs-10 pt-20 pr-0 fontFamily">
                                    <div className="item-toko font-w600 font-size16 fontFamily">
                                    MBtech
                                    </div>
                                    <div className="item-alamat fontFamily">
                                    Jalan Boulevard Raya Blok WE2 No.3D Kelapa Gading Timur. Kelapa Gading, Kota Jakarta Utara Daerah Khusus Ibukota Jakarta, 14240
                                    </div>
                                    <div className="item-contact fontFamily">
                                    02145842598
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-xs-12 pl-0 pr-0">
                                <hr className="dotted "/>
                                <svg className="print-iconCut" xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                    <path d="M4.17263 4.95L0.0864277 1.01679C-0.0287285 0.905953 -0.0287285 0.726344 0.0864277 0.615742C0.891785 -0.159414 2.19779 -0.159414 3.00315 0.615742L5.83933 3.3458L6.44973 2.75829C6.34391 2.50022 6.28571 2.21923 6.28571 1.925C6.28571 0.671988 7.34103 -0.34375 8.64286 -0.34375C9.94469 -0.34375 11 0.671988 11 1.925C11 3.17801 9.94469 4.19375 8.64286 4.19375C8.53138 4.19375 8.42212 4.18619 8.31482 4.17177L7.50627 4.95L8.31482 5.72823C8.42212 5.71381 8.53163 5.70625 8.64286 5.70625C9.94469 5.70625 11 6.72199 11 7.975C11 9.22801 9.94469 10.2438 8.64286 10.2438C7.34103 10.2438 6.28571 9.22801 6.28571 7.975C6.28571 7.68077 6.34391 7.39978 6.44973 7.14171L5.83933 6.5542L3.00315 9.28426C2.19779 10.0594 0.891785 10.0594 0.0864277 9.28426C-0.0287285 9.17366 -0.0287285 8.99405 0.0864277 8.88321L4.17263 4.95ZM8.64286 2.68125C9.07598 2.68125 9.42857 2.34188 9.42857 1.925C9.42857 1.50812 9.07598 1.16875 8.64286 1.16875C8.20973 1.16875 7.85714 1.50812 7.85714 1.925C7.85714 2.34188 8.20973 2.68125 8.64286 2.68125ZM8.64286 8.73125C9.07598 8.73125 9.42857 8.39188 9.42857 7.975C9.42857 7.55812 9.07598 7.21875 8.64286 7.21875C8.20973 7.21875 7.85714 7.55812 7.85714 7.975C7.85714 8.39188 8.20973 8.73125 8.64286 8.73125Z" fill="#545454"/>
                                </svg>
                            </div>

                            <div className="col-lg-1 col-xs-2 pl-0 pr-0 fontFamily" style={{color:'#717171'}}>
                                Produk
                            </div>

                            <div className="col-lg-6 col-xs-6 fontFamily">
                                <div className="col-lg-12 col-xs-12 item-toko font-w600 font-size16 fontFamily">
                                    Catalog
                                </div>
                                <div className="col-lg-12 col-xs-12 pr-0  item-toko font-w500 font-size16 fontFamily">
                                {itemPrint.product && 
                                    itemPrint.product.map((item, i) =>(
                                        item.varian === 'Custom Product' ?
                                        <div>{'Custom'}</div>
                                        :
                                        <div>{item.varian}</div>
                                ))}
                                </div>
                            </div>

                            <div className="col-lg-5 col-xs-4 fontFamily">
                                <div className="col-lg-12 col-xs-12 pl-0 item-toko font-w600 font-size16 fontFamily">
                                    Jumlah
                                </div>
                                <div className="col-lg-12 col-xs-12 pl-0 item-toko font-w500 font-size16 fontFamily">
                                    {itemPrint.product && 
                                        itemPrint.product.map((item, i) =>(
                                            item.varian === 'Custom Product' ?
                                                <div>{item.color}</div>
                                                :
                                                <div>{item.total}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    );
}
export default ComponentPrintMasterSample;