import http from "../http-PTS";

// const API_URL = 'http://localhost:8080/api/';
// const API_URL = 'http://192.168.3.26:8080/api/';
const API_URL = process.env.REACT_APP_API_URL;
class UserService {
    login(username, password){
        return http.post('/login',{
                username,
                password
            })
    }

    logout(){
        localStorage.removeItem("userPTS");
        sessionStorage.clear();
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('userPTS'));;
    }
}

export default new UserService();