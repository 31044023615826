import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import ServicesPTS from "../../../Services/ServicesPTS";

const EditMasterSample = () => {
    const viewSample = process.env.REACT_APP_APP_URL+'tool-support/master-sample';
    const history = useHistory();
    const { id } = useParams();

    const [dataMS, setDataMS] = useState([]);
    const [filterMS, setFilterMS] = useState([
        {
            dataProId:"",
            sendTo: "",
            noReceipt: "",
            name: "",
            address: "",
            phone: "",
            toko:""
        }
    ]);
    const [updateMS, setUpdateMS] = useState();
    const [products, setProducts] =  useState([]);
    

    useEffect(() => {
        getMasterBannerId();
    },[]);

    const getMasterBannerId = () =>{
        var tempId = id;

        ServicesPTS.getDetailMasterSample(tempId)
            .then((response) => {
                setDataMS(response.data);
                setFilterMS({
                    dataProId: response.data[0].dataProId,
                    noReceipt: response.data[0].noReceipt,
                    name: response.data[0].name,
                    address: response.data[0].address,
                    phone: response.data[0].phone,
                    toko: response.data[0].toko,
                });
                setUpdateMS({
                    dataProId: response.data[0].dataProId,
                    noReceipt: response.data[0].noReceipt,
                    name: response.data[0].name,
                    address: response.data[0].address,
                    phone: response.data[0].phone,
                    product: response.data[0].product,
                });
                setProducts(response.data[0].product);
            })
            .catch(e =>{
                console.log(e);
            });       
    }
    // console.log(products)
    const onChangeProducts = (e) =>{
        const product = products;
        const val = e.target.value;
        var id = e.target.id;
        var rest = [];
        if(id == 'Custom Product'){
            rest = 
                {
                    varian : id,
                    total : Number(0),
                    color : val,
                }
        }else{
            rest = 
                {
                    varian : id,
                    total : Number(val),
                    color : "",
                }
        }
        product.push({
            ...rest,
        });
        const result = [...new Map(product.map(o => [o.varian, o])).values()];
        
        setProducts(result);
    }

    const targetPhone = (e) => {
        e.preventDefault();
        const tar = e.target.value;
        setFilterMS((prevState) => ({
                ...prevState,
                ...{phone: tar},
        }));
    } 
    const targetAddress = (e) => {
        e.preventDefault();
        const tar = e.target.value;
        setFilterMS((prevState) => ({
                ...prevState,
                ...{address: tar},
        }));
    }
    const targetName = (e) => {
        e.preventDefault();
        const tar = e.target.value;
        setFilterMS((prevState) => ({
                ...prevState,
                ...{name: tar},
        }));
    }
    const targetNoReceipt = (e) => {
        const tar = e.target.value;
        setFilterMS((prevState) => ({
                ...prevState,
                ...{noReceipt: tar}
        }));
    }
    

    
    const onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const editMS = (e) => {
        e.preventDefault();
        var ids = id;
        var product = [...new Map(products.map(o => [o.varian, o])).values()]
        var data = {
            dataProId: filterMS.dataProId,
            sendTo: filterMS.sendTo,
            noReceipt: filterMS.noReceipt,
            name: filterMS.name,
            address: filterMS.address,
            phone: filterMS.phone,
            product: product,
        }
        ServicesPTS.editMasterSample(ids, data)
        .then(response => {
            getMasterBannerId();
            window.location.reload(false);
            console.log(filterMS);
        })
        .catch(e => {
            console.log(e);
        })
    }
    
    // console.log(products);
    
    return(
        <div className="row">
                <div className="col-lg-12 col-md-12 pb-10 box-shadow-1 border-radius-10 mt-pto-1 border-left-20 mb-17per">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <a href={viewSample}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span className="span-breadcrumb pl-15">Master Sample</span>
                            </a>
                            {/* <span className="span-breadcrumb pl-15">/ Input Master Sample </span> */}
                        </div>
                    </div>
                    <div className="row pl-20 color-1 fontFamily">
                        <div className="col-lg-12 pl-0" id="title-h1">
                            {dataMS.map((index) => (
                                <h1>Edit Master Sample <span className="namaToko">{index.toko}</span></h1>
                            ))}
                            
                        </div>
                        <div className="col-lg-12">
                            <div className="row">
                                {/* <form method="POST" encType="multipart/form-data" ref={form => this.form = form}> */}
                                <form className="col-lg-12" method="POST" encType="multipart/form-data" >
                                    {products &&
                                        products.map((index,i) =>{
                                            if(index.varian === 'Custom Product'){
                                                return (
                                                    <div key={i} class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for={index.varian}>{index.varian}</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <div className="bg-jumlah d-flex" for={index.varian}>
                                                                    <textarea 
                                                                        id={index.varian} 
                                                                        className=""
                                                                        rows="4" 
                                                                        cols="29"
                                                                        defaultValue={index.color}
                                                                        onChange={onChangeProducts}
                                                                        placeholder="Cara Penulisan 'Warna Hijau Semua / MB9001 : 1'"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }else if(index.varian !== 'Custom Product'){
                                                return (
                                                    <div key={i} class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily">{index.varian}</label>
                                                            </div>
                                                            <div className="col-lg-10 col-xs-12 d-flex">
                                                                <div className="bg-jumlah col-lg-5 col-md-5 col-xs-11" for={index.varian}>
                                                                    <label 
                                                                        className="col-lg-3 col-xs-3 pl-0 pt-10"
                                                                        for={index.varian}
                                                                    >
                                                                        Jumlah
                                                                    </label>
                                                                    <input 
                                                                        type="number" 
                                                                        min={0} 
                                                                        onChange={onChangeProducts} 
                                                                        id={index.varian}
                                                                        defaultValue={index.total}
                                                                        className="button-typeText col-lg-9 text-effect col-xs-9"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            };
                                        }
                                        
                                    )}
                                    {/* {products &&
                                    products.splice(-1,1).map((index) => (
                                        <div class="form-group">
                                            <div className="row">
                                                <div className="col-lg-2 pl-0">
                                                    <label className="font-w400 fontFamily" for="Custom Product">Custom Product</label>
                                                </div>
                                                <div className="col-lg-10 d-flex">
                                                    <div className="bg-jumlah d-flex" for="Custom Product">
                                                        <textarea 
                                                            id="Custom Product" 
                                                            className=""
                                                            rows="4" 
                                                            cols="29"
                                                            defaultValue={index.color}
                                                            onChange={this.onChangeProducts}
                                                            placeholder="Cara Penulisan 'Warna Hijau Semua / MB9001 : 1'"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
                                    {/* {product.map((item, i, arr) => {
                                        if(arr.length - 1 === i){
                                            return (
                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="customProduct">{item.varian}</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <div className="bg-jumlah d-flex" for="customProduct">
                                                                <textarea 
                                                                    id={item.varian}  
                                                                    className=""
                                                                    rows="4" 
                                                                    cols="29"
                                                                    defaultValue={item.color}
                                                                    onChange={this.onChangeProducts}
                                                                    placeholder="Cara Penulisan 'Warna Hijau Semua / MB9001 : 1'"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }else{
                                            return (
                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily">{item.varian}</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <div className="bg-jumlah " for={item.varian}>
                                                                <label for={item.varian}>Jumlah</label>
                                                                <input 
                                                                    type="number" 
                                                                    min={0} 
                                                                    onChange={this.onChangeProducts} 
                                                                    id={item.varian}
                                                                    defaultValue={item.total}
                                                                    className="ml-15 button-typeText text-effect"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }

                                        
                                     )} */}
                                    {/* (
                                            
                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0">
                                                        <label className="font-w400 fontFamily">{index.varian}</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <div className="bg-jumlah " for="jumlahCamaro">
                                                            <label for="jumlahCamaro">Jumlah</label>
                                                            <input 
                                                                type="number" 
                                                                min={0} 
                                                                onChange={this.onChangeProducts} 
                                                                id={index.varian}
                                                                defaultValue={index.total}
                                                                className="ml-15 button-typeText text-effect"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) */}

                                    
                                    {/* {product.slice(-1).map((index) =>(
                                        <div class="form-group">
                                            <div className="row">
                                                <div className="col-lg-2 pl-0">
                                                    <label className="font-w400 fontFamily" for="customProduct">{index.varian}</label>
                                                </div>
                                                <div className="col-lg-10 d-flex">
                                                    <div className="bg-jumlah d-flex" for="customProduct">
                                                        <textarea 
                                                            id={index.varian}  
                                                            className=""
                                                            rows="4" 
                                                            cols="29"
                                                            defaultValue={index.color}
                                                            onChange={this.onChangeProducts}
                                                            placeholder="Cara Penulisan 'Warna Hijau Semua / MB9001 : 1'"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
                                    
                                    

                                   
                                    
                                    <div class="form-group">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Dikirim Kepada</h3>
                                            </div>
                                            {dataMS && dataMS.map((index) => (
                                                <div className="container-fluid">
                                                    
                                                        <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    name="noReceipt"  
                                                                    defaultValue={index.noReceipt}
                                                                    onChange={targetNoReceipt} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    name="name"  
                                                                    defaultValue={index.name} 
                                                                    onChange={targetName} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    name="address"  
                                                                    defaultValue={index.address}
                                                                    onChange={targetAddress} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0 font-w100">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    onKeyPress={onKeyNumber} 
                                                                    name="phone"
                                                                    defaultValue={index.phone} 
                                                                    onChange={targetPhone} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <button onClick={(e) => editMS(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Simpan</button>
                                    </div>
                                </form>
                            </div>

                                

                        </div>
                    </div>
                    
                </div>
                
            </div>
    );
}
export default EditMasterSample;