import React, { Component } from "react";
import { Modal,Button,} from "react-bootstrap";

// import "../../../assets/css/toolSupport.css";

import servicesPTS from "../ServicesPTS/ServicesPTS";

// image



class InputOrder extends Component {
    
    constructor(props) {
        super(props);        
        this.state = {
            dataPTS: [],
            value: "",
            dataProId: "",
            jenisShopBanner: "",
            customHeightBanner: "",
            customWidthBanner: "",
            image: null,
            imagePreview: null,
            

            display_name: "",
            display_tagline: "",
            display_contact: "",

            isName: 0,
            defaultCheckName: false,
            isTagline: 0,
            defaultCheckTagline: true,
            isContact: 0,
            defaultCheckContact: true,

            recipient: "",
            delivery_address: "",
            phone: "",
            
            statusSave: "",

            isOpen: true,
            setShow:false,
        };
        
        this.targetDataProId = this.targetDataProId.bind(this);
        this.targetJenisShopBanner = this.targetJenisShopBanner.bind(this);
        this.targetHeightConfig = this.targetHeightConfig.bind(this);
        this.targetWidthConfig = this.targetWidthConfig.bind(this);
        this.onImageChange = this.onImageChange.bind(this);

        this.targetDisplayName = this.targetDisplayName.bind(this);
        this.targetDisplayTagline = this.targetDisplayTagline.bind(this);
        this.targetDisplayContact = this.targetDisplayContact.bind(this);
        this.targetIsName = this.targetIsName.bind(this);
        this.targetIsTagline = this.targetIsTagline.bind(this);
        this.targetIsContact = this.targetIsContact.bind(this);

        this.targetRecipient = this.targetRecipient.bind(this);
        this.targetDeliveryAddress = this.targetDeliveryAddress.bind(this);
        this.targetPhone = this.targetPhone.bind(this);

        this.handleCheckIsName = this.handleCheckIsName.bind(this);
        this.handleCheckIsTagline = this.handleCheckIsTagline.bind(this);
        this.handleCheckIsContact = this.handleCheckIsContact.bind(this);
        

        this.refresh = this.refresh.bind(this);
        this.savePTS = this.savePTS.bind(this);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        
    }

    componentDidMount() {  
        // this.defaultCheckContact();
    }


    handleShow(){
        this.setState(
            { 
                setShow: true 
            }
        );
    }
    handleClose(){
        this.setState(
            { 
                setShow: false 
            }
        );
    }
    
    targetDataProId(e){
        this.setState(
            {
                dataProId : e.target.value
            },
        );
    }

    targetJenisShopBanner(e){
        this.setState(
            {
                jenisShopBanner : e.target.value
            },
        );
    }

   

    targetHeightConfig(e){
        this.setState(
            {
                customHeightBanner : e.target.value
            },
        );
    }

    targetWidthConfig(e){
        this.setState(
            {
                customWidthBanner : e.target.value
            },
        );
    }
    
    onImageChange(e){
        // let file = new File(`${Date.now()}-`);
        const newDate = new Date();
        const dateString =
        newDate.getUTCFullYear() + "-" +
            ("0" + (newDate.getUTCMonth()+1)).slice(-2) + "-" +
            ("0" + newDate.getUTCDate()).slice(-2);
        
        const file = new File([""] ,`${dateString}-`+e.target.files[0].name, {
            type: "image/jpeg",
            lastModified: new Date(),
          })
        // let img = e.target.files[0];
        const img = file;
        const imgPreview = e.target.files[0]
        this.setState({
            image: img,
            imagePreview: URL.createObjectURL(imgPreview) 
        },
        ()=>{console.log("namafile",this.state.imagePreview)}
        );
        
    }

    targetDisplayName(e){
        // if(this.state.isName == Number(0)){
            // this.setState(
                // {
                    // display_name: '',
                // },
            // )
        // }else if(this.state.isName == Number(1)){
            this.setState(
                {
                    display_name: e.target.value,
                },
            )
        // }
        
    }
    targetDisplayTagline(e){
        this.setState(
            {
                display_tagline: e.target.value
            },
        )
    }
    targetDisplayContact(e){
        this.setState(
            {
                display_contact: e.target.value
            },
        )
    }
    targetIsName(e){
        this.setState(
            {
                isName : e.target.value
            },
        );
    }
    targetIsTagline(e){
        this.setState(
            {
                isTagline : e.target.value
            },
        );
    }
    targetIsContact(e){
        this.setState(
            {
                isContact : e.target.value
            }
        );
    }
    
    targetRecipient(e){
        this.setState(
            {
                recipient : e.target.value
            }
        )
    }
    targetDeliveryAddress(e){
        this.setState(
            {
                delivery_address : e.target.value
            }
        )
    }
    targetPhone(e){
        this.setState(
            {
                phone : e.target.value
            }
        )
    }


    handleCheckIsName(e){
        const defaultCheckContact = e.target.checked;
        if(defaultCheckContact == true){
            this.setState(
                {
                    isName: Number(1),
                },
                // ()=>{console.log(this.state.isName);}
            )
        }else{
            this.setState(
                {
                    isName: Number(0),
                    display_name: "",
                },
                // ()=>{console.log(this.state.isName);}
            )
        }
    }
    handleCheckIsTagline(e){
        const defaultCheckContact = e.target.checked;
        if(defaultCheckContact == true){
            this.setState(
                {
                    isTagline: Number(1),
                },
                ()=>{console.log(this.state.isTagline);}
            )
        }else{
            this.setState(
                {
                    isTagline: Number(0),
                },
                ()=>{console.log(this.state.isTagline);}
            )
        }
    }
    handleCheckIsContact(e){
        const defaultCheckContact = e.target.checked;
        if(defaultCheckContact == true){
            this.setState(
                {

                    isContact: Number(1),
                },
                // ()=>{console.log(this.state.isContact);}
            )
        }else{
            this.setState(
                {
                    isContact: Number(0),
                },
                // ()=>{console.log(this.state.isContact);}
            )
        }
    }
    
    // event untuk div konfigurasi
    onChangeDiv(w, h){
        const width = Number(w);
        const height = Number(h);
        var text =  'Bentuk Gambar';
        if(width === height){
            return(
                <div className="config fontFamily font-size14 text-center" 
                    style={{
                        width: 100, 
                        height: 100, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }else if(width <= height){
            return(
                <div className="config1 fontFamily font-size14 text-center" 
                    style={{
                        width: 100, 
                        height: 150, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }else if(width >= height){
            return(
                <div className="config2 fontFamily font-size14 text-center" 
                    style={{
                        width: 150, 
                        height: 100, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }
    }

    // handleSubmitOrder(e){
    //     console.log('handle submit');
    //     var data = {
    //         type: this.state.jenisShopBanner,
    //         size: [this.state.customWidthBanner, this.state.customHeightBanner],
    //         image: this.state.image,
    //         delivery_address: "",
    //         recipient: "",
    //         phone: "",
    //         display_name: this.state.teksNamaToko,
    //         display_tagline: this.state.tagline,
    //         display_contact: this.state.contactAddress,
    //         isName: "",
    //         isTagline: "",
    //         isContact: "",

    //     }

    //     e.preventDefault();
    //     e.target.reset();
    //     console.log(data);
        
    // }

    // REGEX Number
    onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    // getToolsBanner(){
    //     servicesPTS.getAllPts()
    //         .then((response) =>{
    //             this.setState({
    //                 dataPTS:response.data,
    //             });
    //             // console.log(response.data)
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    // }

    refresh(){
        this.setState({})
    }

    savePTS(e){
        
        var data = {
            dataProId: this.state.dataProId,
            type: this.state.jenisShopBanner,
            size: this.state.customWidthBanner+"x"+this.state.customHeightBanner,
            files: this.state.image,
            
            display_name: this.state.display_name,
            display_tagline: this.state.display_tagline,
            display_contact: this.state.display_contact,
            isName: this.state.isName,
            isTagline: this.state.isTagline,
            isContact: this.state.isContact,
            
            recipient: this.state.recipient,
            delivery_address: this.state.delivery_address,
            phone: this.state.phone,
        }
        
        servicesPTS.createOrderBanner(data)
        .then(response => {
            
            this.setState({
                dataProId: response.data.dataProId,
                type: response.data.jenisShopBanner,
                size: response.data.size,
                files: response.data.files,
                
                display_name: response.data.display_name,
                display_tagline: response.data.display_tagline,
                display_contact: response.data.display_contact,
                isName: response.data.isName,
                isTagline: response.data.isTagline,
                isContact: response.data.isContact,
                
                delivery_address: response.data.delivery_address,
                recipient: response.data.recipient,
                phone: response.data.phone,
            });
            
            if(response.data.success == true){
                console.log(response.data.success);
                this.props.history.push({
                    pathname: '/tool-support/order',
                    state :{
                        id: this.state.dataProId
                    },
                },
                // ()=>{console.log(this.state.id);}
                );
                this.setState({
                    dataProId : this.state.dataProId,
                },
                // ()=>{console.log(this.state.dataProId);}
                );
                
            }
            
        })
        .catch(e => {
            console.log(e);
        });
        e.preventDefault();
        
    }

    render() {
        const {
            dataProId,
            customWidthBanner,
            customHeightBanner,
          } = this.state;

          

        return (
            
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <span className="span-breadcrumb pl-15">Order Banner</span>
                        </div>
                    </div>
                    <div className="row pl-40 color-1 fontFamily">
                        <div className="col-lg-12 pl-0" id="title-h1">
                            <h1>Input Shop Banner</h1>
                        </div>
                        <div className="col-lg-12">
                            
                                <form method="POST" encType="multipart/form-data" ref={form => this.form = form}>
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama Toko</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <input type="text" className="button-typeText" id="formUkuran2" name="dataProId"  value={this.state.targetDataProId} onChange={this.targetDataProId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="jenisShopBanner">Jenis Shop Banner</label>
                                            </div>
                                            <div className="col-lg-3 col-xs-7">
                                                <select class="form-control" name="jenisShopBanner" value={this.state.targetJenisShopBanner} onChange={this.targetJenisShopBanner}>
                                                    <option selected hidden>Default select</option>

                                                    <option value="mobil">Mobil</option>
                                                    <option value="motor">Motor</option>
                                                    <option value="sofa">Sofa</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="ukuranShopBanner">Ukuran Shop Banner</label>
                                            </div>
                                            <div className="col-lg-3 col-xs-7">
                                                <select class="form-control" name="ukuranShopBanner">
                                                    <option selected disabled hidden>Default select</option>
                                                    <option value="custom">Custom</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Ukuran Custom</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <input type="text" className="button-typeText1" onKeyPress={this.onKeyNumber} id="formUkuran2" name="customWidthBanner" value={this.state.targetWidthConfig} onChange={this.targetWidthConfig} />
                                                <div className="xUkuran">x</div>
                                                <input type="text" className="button-typeText1" onKeyPress={this.onKeyNumber} id="formUkuran" name="customHeightBanner" value={this.state.targetHeightConfig} onChange={this.targetHeightConfig} />
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Konfigurasi</label>
                                            </div>
                                            <div className="col-lg-10">
                                                {this.onChangeDiv(customWidthBanner, customHeightBanner)}
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Foto</label>
                                            </div>
                                            <div className="col-lg-5">
                                                <div id="uploadFoto" className="d-grid mx-auto">
                                                    
                                                    {/* <input className="" id="fileHidden" 
                                                        name="filename" 
                                                        type="file" 
                                                        accept="image/*"
                                                    /> */}
                                                    <div className="" id="previewFoto">
                                                        {/* Preview */}
                                                        <div>
                                                            <img src={this.state.imagePreview} width="150"/>
                                                            <input type="file" id="my_file" name="image" onChange={this.onImageChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="mx-auto" id="preview">
                                                        <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="118" height="114" viewBox="0 0 118 114" fill="none">
                                                            <g clip-path="url(#clip0_76_623)">
                                                                <path d="M48.011 50.25C48.011 52.438 47.1418 54.5365 45.5946 56.0836C44.0474 57.6308 41.949 58.5 39.761 58.5C37.5729 58.5 35.4745 57.6308 33.9274 56.0836C32.3802 54.5365 31.511 52.438 31.511 50.25C31.511 48.062 32.3802 45.9635 33.9274 44.4164C35.4745 42.8692 37.5729 42 39.761 42C41.949 42 44.0474 42.8692 45.5946 44.4164C47.1418 45.9635 48.011 48.062 48.011 50.25V50.25Z" fill="#DFE6EA"/>
                                                                <path d="M23.25 31C21.062 31 18.9635 31.8692 17.4164 33.4164C15.8692 34.9635 15 37.062 15 39.25L15 88.75C15 90.938 15.8692 93.0365 17.4164 94.5836C18.9635 96.1308 21.062 97 23.25 97H94.75C96.938 97 99.0365 96.1308 100.584 94.5836C102.131 93.0365 103 90.938 103 88.75V39.25C103 37.062 102.131 34.9635 100.584 33.4164C99.0365 31.8692 96.938 31 94.75 31H23.25ZM94.75 36.5C95.4793 36.5 96.1788 36.7897 96.6945 37.3055C97.2103 37.8212 97.5 38.5207 97.5 39.25V72.25L76.7375 61.5415C76.2217 61.2831 75.6378 61.1935 75.0682 61.2853C74.4987 61.3771 73.9725 61.6457 73.564 62.053L53.159 82.458L38.529 72.712C38.0008 72.3603 37.3672 72.2022 36.7357 72.2643C36.1041 72.3265 35.5135 72.6051 35.064 73.053L20.511 86V88.97C20.5044 88.8968 20.5008 88.8234 20.5 88.75V39.25C20.5 38.5207 20.7897 37.8212 21.3055 37.3055C21.8212 36.7897 22.5207 36.5 23.25 36.5H94.75Z" fill="#DFE6EA"/>
                                                            </g>
                                                            <line x1="11" y1="8.5" x2="107" y2="8.5" stroke="#DFE6EA" stroke-width="3"/>
                                                            <line x1="21" y1="1.5" x2="97" y2="1.5" stroke="#DFE6EA" stroke-width="3"/>
                                                            <rect x="1.5" y="16.5" width="115" height="96" rx="2.5" stroke="#DFE6EA" stroke-width="3"/>
                                                            <defs>
                                                                <clipPath id="clip0_76_623">
                                                                    <rect width="88" height="88" fill="white" transform="translate(15 20)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div> */}
                                                    {/* <input className="buttomSubmit mx-auto mt-30" type="button" value="Upload"/> */}
                                                    {/* <label className="d-block mx-auto color-2 fontFamily font-w400 font-size12">(Upload Kondisi Shop Banner/Lokasi)</label> */}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Teks di Shop Banner</h3>
                                            </div>
                                            <div className="container-fluid">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama Toko</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="checkbox" 
                                                                id="topping" 
                                                                name="isName" 
                                                                value="" 
                                                                onChange={this.handleCheckIsName}
                                                                defaultChecked={this.state.defaultCheckName}
                                                            /> 
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="display_name"  
                                                                value={this.state.isName === 0 ? '' : this.state.targetDisplayName}
                                                                onChange={this.targetDisplayName}
                                                                disabled={this.state.isName === 0 ? true : false}
                                                            />
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group"> 
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Tagline</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="checkbox" 
                                                                id="topping" 
                                                                name="isTagline" 
                                                                value="" 
                                                                onChange={this.handleCheckIsTagline}
                                                                defaultChecked={this.state.defaultCheckTagline}
                                                            />
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="display_tagline"  
                                                                value={this.state.isTagline === 0 ? '' : this.state.targetDisplayTagline} 
                                                                onChange={this.targetDisplayTagline} 
                                                                disabled={this.state.isTagline === 0 ? true : false}
                                                            />
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak/Alamat</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="checkbox" 
                                                                id="topping" 
                                                                name="isContact" 
                                                                value=""
                                                                // checked=""
                                                                onChange={this.handleCheckIsContact}
                                                                defaultChecked={this.state.defaultCheckContact}
                                                            />
                                                            <textarea 
                                                                rows="4" 
                                                                className="button-typeTextarea" 
                                                                name="display_contact"  
                                                                value={this.state.isContact === 0 ? '' : this.state.targetDisplayContact}
                                                                onChange={this.targetDisplayContact}
                                                                // style={{display: this.state.isContact ? 'block' : 'none'}}
                                                                disabled={this.state.isContact === 0 ? true : false}
                                                            >
                                                            </textarea>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Dikirim Kepada</h3>
                                            </div>
                                            <div className="container-fluid">
                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input type="text" className="button-typeText" id="formUkuran2" name="sendResi"  value={this.state.targetSendResi} onChange={this.targetSendResi} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input type="text" className="button-typeText" id="formUkuran2" name="recipient"  value={this.state.targetRecipient} onChange={this.targetRecipient} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input type="text" className="button-typeText" id="formUkuran2" name="delivery_address"  value={this.state.targetDeliveryAddress} onChange={this.targetDeliveryAddress} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 font-w100">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input type="text" className="button-typeText" id="formUkuran2" onKeyPress={this.onKeyNumber} name="phone"  value={this.state.targetPhone} onChange={this.targetPhone} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <button onClick={(e) => this.savePTS(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Kirim Permintaan</button>
                                    </div>

                                </form>

                                

                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default InputOrder;