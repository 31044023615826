import React from "react";
import '../modal/modal.css';
import { Button, Modal, Row } from 'react-bootstrap';
import { Container } from "react-bootstrap/lib/Tab";
// import Modal from 'react-bootstrap/Modal';

const ModalDetil = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>{props.header}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
                <Row>
                    {props.body}
                </Row>
            </Container>
            
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
}

export default ModalDetil;