
import { toHaveFormValues } from "@testing-library/jest-dom";
import React, { Component, useRef, useState } from "react";
import { Modal,Button,} from "react-bootstrap";

import PropTypes from 'prop-types';

import "../../../assets/css/PTS/inputMasterSample.css";


import Autocomplete from "./autoComplete/AutoComplete"; 
import { testingx, testy } from "./autoComplete/Testing"; 
import SeatmakerDataService from "../../../services/smaker.service";
import servicesPTS from "../ServicesPTS/ServicesPTS";

// image



class InputMasterSample extends Component {
    customRef = React.createRef();
    constructor(props) {
        super(props);        
        this.state = {
            // MS = Master Sample
            dataMS: [],
            insertMS:{

            },

            id: 0,
            dataProId: 222,
            sendTo: "",
            products:[
                    {
                        "varian": "Camaro",
                        "total": 0,
                        "color": "",
                    },
                    {
                        "varian": "Camaro Fiesta",
                        "total": 0,
                        "color": "",
                    },
                    {
                        "varian": "Giorgio",
                        "total": 0,
                        "color": "",
                    },
                    {
                        "varian": "New Superior",
                        "total": 0,
                        "color": "",
                    },
                    {
                        "varian": "Picasso",
                        "total": 0,
                        "color": "",
                    },
                    {
                        "varian": "Premium Carrera",
                        "total": 0,
                        "color": "",
                    },
                    {
                        "varian": "Rider Cruiser",
                        "total": 0,
                        "color": "",
                    },
                    {
                        "varian": "Custom Product",
                        "total": 0,
                        "color": "",
                    },
            ],
            
            
            
            noReceipt: "",
            name: "",
            address: "",
            phone: "",

            name: "",
            size: "",
            total: 0,
            color: "",

            penjumlahan: 0,

            // toko:[],
            
            items:[],
            isLoaded:false,
            idToko: "",
        };
        
        this.onChangeProducts = this.onChangeProducts.bind(this);
        this.onChangeProductsCustom = this.onChangeProductsCustom.bind(this);
        this.funcAddition = this.funcAddition.bind(this);

        // this.blognone = this.blognone.bind(this);
        this.targetIdToko = this.targetIdToko.bind(this);
        this.targetPhone = this.targetPhone.bind(this);
        this.targetAddress = this.targetAddress.bind(this);
        this.targetName = this.targetName.bind(this);
        this.targetNoReceipt = this.targetNoReceipt.bind(this);
        this.saveMS = this.saveMS.bind(this);
        this.getToko = this.getToko.bind(this);
        
    }

    componentDidMount() {  
        // this.saveMS = this.saveMS.bind(this);
        // console.log(this.getToko('bowo'));

    }
    
    getToko(){
        var toko = this.state.idToko;
        var temp = [];
        fetch(`https://mbtech.app/api/smaker/?toko=${toko}`)
            .then(results => results.json())
            .then(json => {
                for(let i=0; i < json.seatmakers.length; i++){
                    temp.push(
                        json.seatmakers[i].toko
                    )
                    i = i++;
                }
            })
            .catch(error => {
                console.error(error);
            });
            this.setState(
                {temp}
            )
            console.log(temp)
            return temp;
    }
    
    onChangeProducts(e){
        const products = this.state.products;
        console.log('testing',products);
        const val = e.target.value;
        var id = e.target.id;
        if(id === "Custom Product"){
            products.push(
                {
                    varian: id,
                    total: "0",
                    color: val,
                },
            )
        }else{
            products.push(
                {
                    varian: id,
                    total: val,
                    color: "",
                },
            )
        }
        // this.funcAddition();
    }
    onChangeProductsCustom(e){
        const products = this.state.products;
        const val = e.target.value;
        var id = e.target.id;
        products.push(
            {
                varian: id,
                total: '0',
                color: val,
            },
        )
    }

    targetPhone(e){
        this.setState(
            {
                phone: e.target.value
            },
        )
    }
    targetAddress(e){
        this.setState(
            {
                address: e.target.value
            },
        )
    }
    targetName(e){
        this.setState(
            {
                name: e.target.value
            },
        )
    }
    targetNoReceipt(e){
        this.setState(
            {
                noReceipt: e.target.value
            },
        )
    }
    targetIdToko(e){
        this.setState(
            {
                idToko: e.target.value
            },
        )
    }
    

    
    onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    funcAddition(){
        const temp_products = [...new Map(this.state.products.map(o => [o.varian, o])).values()];
        let jumlahnya = 0;
        for(let i = 0; i < temp_products.length; i++){
            jumlahnya += Number(temp_products[i].total);
        }
        this.setState(
            {
                penjumlahan : jumlahnya
            }
        )
    }

    saveMS(e){
        e.preventDefault();
        const products = [...new Map(this.state.products.map(o => [o.varian, o])).values()]
        console.log(this.state.products);
        var data = {
            dataProId : this.state.dataProId,
            sendTo :this.state.sendTo,
            noReceipt : this.state.noReceipt,
            name : this.state.name,
            address : this.state.address,
            phone : this.state.phone,
            product: products,
        }
        console.log('asss', data);

        this.setState({
            data
        })
        servicesPTS.createMasterSample(data)

        .then(response => {
            if(response.data.success == true){
                this.props.history.push({
                    pathname: '/tool-support/master-sample',
                    state:{
                        
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
        })
    }
    

    render() {
        const {
            id,
            total,
            products,
            items,
            idToko
        } = this.state;
        // console.log(testy('sama') + testingx('12'));
        
        // this.getToko('bowo');
        // console.log('testing page:',Autocomplete.);
        // console.log(Autocomplete.state.filteredOptions)
        
        return (
            
            
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <span className="span-breadcrumb pl-15">Master Sample</span>
                            {/* <span className="span-breadcrumb pl-15">/ Input Master Sample </span> */}
                        </div>
                    </div>
                    <div className="row pl-40 color-1 fontFamily">
                        <div className="col-lg-12 pl-0" id="title-h1">
                            <h1>Input Master Sample</h1>
                        </div>
                        <div className="col-lg-12">
                            <div className="row">
                            <div className="col-lg-12 pl-0">
                                <h3><strong>Request Varian Master Sample</strong></h3>
                            </div>
                                <form className="col-lg-12" method="POST" encType="multipart/form-data" ref={form => this.form = form}>
                                
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily">Nama Toko</label>
                                        </div>
                                        <div className="col-lg-10 d-flex">
                                            <div className="bg-jumlah " for="jumlahCamaro">
                                                
                                                {/* <Autocomplete
                                                    options={[idToko]}
                                                /> */}
                                                {/* <Testing
                                                    options={this.targetIdToko}
                                                 /> */}
                                            </div>
                                            
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Camaro</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahCamaro">
                                                    <label for="jumlahCamaro">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts} 
                                                        id="Camaro" 
                                                        className="ml-15 button-typeText text-effect"
                                                    />
                                                </div>
                                                
                                                
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Camaro Fiesta</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahCamaroFiesta">
                                                    <label for="jumlahCamaroFiesta">Jumlah</label>
                                                    <input 
                                                        type="number"
                                                        min={0}
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts} 
                                                        id="Camaro Fiesta" 
                                                        className="ml-15 button-typeText text-effect"
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Giorgio</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahgiorgio">
                                                    <label for="jumlahgiorgio">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Giorgio" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">New Superior</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahnewSuperior">
                                                    <label for="jumlahnewSuperior">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0} 
                                                        id="New Superior" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Picasso</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahpicasso">
                                                    <label for="jumlahpicasso">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Picasso" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Premium Carrera</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahpremiumCarrera">
                                                    <label for="jumlahpremiumCarrera">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Premium Carrera" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Riders Cruiser</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahriderCruiser">
                                                    <label for="jumlahriderCruiser">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Rider Cruiser" 
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        className="ml-15 button-typeText text-effect"
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="customProduct">Custom</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah d-flex" for="customProduct">
                                                    <textarea 
                                                        ref={this.customRef}
                                                        id="Custom Product"  
                                                        className=""
                                                        rows="4" 
                                                        cols="29"
                                                        value={this.state.products.color} 
                                                        onChange={this.onChangeProducts}
                                                        placeholder="Cara Penulisan 'Warna Hijau Semua / MB9001 : 1'"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    {/* <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Jumlah Total</label>
                                            </div>
                                            <div className="col-lg-10 d-flex pl-0">
                                                <input 
                                                    value={this.state.penjumlahan}
                                                    disabled 
                                                    id="jumlahcustom" 
                                                    className="ml-15 button-typeText text-effect"
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div class="form-group">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Dikirim Kepada</h3>
                                            </div>
                                            <div className="container-fluid">
                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="noReceipt"  
                                                                value={this.state.noReceipt}
                                                                onChange={this.targetNoReceipt} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="name"  
                                                                value={this.state.name} 
                                                                onChange={this.targetName} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="address"  
                                                                value={this.state.address}
                                                                onChange={this.targetAddress} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0 font-w100">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                onKeyPress={this.onKeyNumber} 
                                                                name="phone"
                                                                value={this.state.phone} 
                                                                onChange={this.targetPhone} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <button onClick={(e) => this.saveMS(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Kirim Permintaan</button>
                                    </div>
                                </form>
                            </div>

                                

                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default InputMasterSample;