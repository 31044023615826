import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Link, useHistory } from 'react-router-dom';

// images
import imageLogo from '../../../assets/img/logo_mbtech.png';

const Header = (props) =>{
    useEffect(() =>{

    },[]);

    return(
        <>
            <div className="container-fluid" id="header">
                <div className="row">
                    <div className="body-padding">
                        <section className="col-xl-12" id="temp-header">
                            <div className="">
                                <img className="img-fluid" src={imageLogo}></img>
                            </div>
                            <div className="">
                                <div>
                                    <p>{props.name}</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Header;