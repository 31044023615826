import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import ServicesPTS from "../../../Services/ServicesPTS";

const EditCatalog = () => {
    const viewCatalog = process.env.REACT_APP_APP_URL+'tool-support/catalog';
    const history = useHistory();
    const { id } = useParams();

    const [dataCatalog, setDataCatalog] = useState([]);
    const [filterCatalog ,setFilterCatalog] = useState([
        {
            dataProId:"",
            sendTo: "",
            noReceipt: "",
            name: "",
            address: "",
            phone: "",
            toko:""
        }
    ]);
    const [updateCatalog, setUpdateCatalog] = useState([
        {
            dataProId:"",
            sendTo: "",
            noReceipt: "",
            name: "",
            address: "",
            phone: "",
            products:""
        }
    ]);
    const [products, setProducts] = useState([]);
    const [penjumlahan, setPenjumlahan] = useState(0);

    useEffect(()=>{
        getCatalogId();
    },[]);

    const getCatalogId = () => {
        var ids = id;
        ServicesPTS.getDetailCatalog(ids)
            .then((response) => {
                setDataCatalog(response.data);
                setFilterCatalog({
                    dataProId: response.data[0].dataProId,
                    noReceipt: response.data[0].noReceipt,
                    name: response.data[0].name,
                    address: response.data[0].address,
                    phone: response.data[0].phone,
                    toko: response.data[0].toko,
                });
                setUpdateCatalog({
                    dataProId: response.data[0].dataProId,
                    noReceipt: response.data[0].noReceipt,
                    name: response.data[0].name,
                    address: response.data[0].address,
                    phone: response.data[0].phone,
                    product: response.data[0].product,
                });
                setProducts(response.data[0].product);
                // this.setState({
                //     dataCatalog: response.data,
                //     filterCatalog:{
                //         dataProId: response.data[0].dataProId,
                //         noReceipt: response.data[0].noReceipt,
                //         name: response.data[0].name,
                //         address: response.data[0].address,
                //         phone: response.data[0].phone,
                //         toko: response.data[0].toko,
                //     },

                //     updateCatalog:{
                //         dataProId: response.data[0].dataProId,
                //         noReceipt: response.data[0].noReceipt,
                //         name: response.data[0].name,
                //         address: response.data[0].address,
                //         phone: response.data[0].phone,
                //         product: response.data[0].product,
                //     },
                //     products: response.data[0].product,
                // });
                
            })
            .catch(e =>{
                console.log(e);
            });
    }

    const onChangeProducts = (e) => {
        const product = products;
        const val = e.target.value;
        var id = e.target.id;
        var rest = [];
        if(id == 'Catalog Book'){
            rest = 
                {
                    varian: id,
                    total: Number(0),
                    color: val,
                }
        }else{
            rest = 
                {
                    varian: id,
                    total: Number(val),
                    color: "",
                }
        }
        product.push({
            ...rest,
        });

        const result = [...new Map(product.map(o => [o.varian, o])).values()];
        setProducts(result);
    }

    const targetPhone = (e) => {
        const tar = e.target.value;
        setFilterCatalog((prevState) => ({
                ...prevState,
                phone: tar,
        }));
    }
    const targetAddress = (e) => {
        const tar = e.target.value;
        setFilterCatalog((prevState) => ({
                ...prevState,
                address: tar,
        }));
    }
    const targetName = (e) => {
        const tar = e.target.value;
        setFilterCatalog((prevState) => ({
                ...prevState,
                name: tar,
        }));
    }
    const targetNoReceipt = (e) => {
        const tar = e.target.value;
        setFilterCatalog((prevState) => ({
                ...prevState,
                noReceipt: tar,
        }));
    }

    const onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const editCatalog = (e) => {
        e.preventDefault();
        var ids = id;
        var product = [...new Map(products.map(o => [o.varian, o])).values()]
        
        console.log(updateCatalog.product);
        var data = {
            dataProId: filterCatalog.dataProId,
            sendTo: filterCatalog.sendTo,
            noReceipt: filterCatalog.noReceipt,
            name: filterCatalog.name,
            address: filterCatalog.address,
            phone: filterCatalog.phone,
            product: product,
        }
        // this.setState(
        //     {
        //         data
        //     }
        // )
        console.log(data);
        ServicesPTS.editCatalog(ids, data)
        .then(response => {
            getCatalogId();
            window.location.reload(false);
            console.log(filterCatalog);
        })
        .catch(e => {
            console.log(e);
        })
        
    }

    return(
        <div className="row">
            <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20 mb-17per">
                <div className="" id="breadcrumb">
                    <div className="padding-h15">
                        <a href={viewCatalog}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className="span-breadcrumb pl-15">Catalog</span>
                        </a>
                        {/* <span className="span-breadcrumb pl-15">/ Input Master Sample </span> */}
                    </div>
                </div>
                <div className="row pl-20 color-1 fontFamily">
                    <div className="col-lg-12 pl-0" id="title-h1">
                        {dataCatalog.map((index) => (
                            <h1>Edit Catalog <span className="namaToko">{index.toko}</span></h1>
                        ))}
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            {/* <form method="POST" encType="multipart/form-data" ref={form => this.form = form}> */}
                            <form className="col-lg-12" method="POST" encType="multipart/form-data" ref={form => form = form}>
                                
                                {products &&
                                    products.map((index,i) =>{
                                        if(index.varian === 'Catalog Book'){
                                            return(
                                                <div key={i} class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for={index.varian}>{index.varian}</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <div className="bg-jumlah d-flex" for={index.varian}>
                                                                <textarea 
                                                                    id={index.varian}  
                                                                    className=""
                                                                    rows="4" 
                                                                    cols="29"
                                                                    defaultValue={index.color}
                                                                    onChange={onChangeProducts}
                                                                    placeholder="Catalog Campuran / Bundle"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }else if(index.varian !== 'Catalog Book'){
                                            return(
                                                <div key={i} class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily">{index.varian}</label>
                                                        </div>
                                                        <div className="col-lg-10 col-xs-12 d-flex">
                                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-11" for={index.varian}>
                                                                <label 
                                                                    className="col-lg-3 col-xs-3 pl-0 pt-10"
                                                                    for={index.varian}
                                                                >
                                                                    Jumlah
                                                                </label>
                                                                <input 
                                                                    type="number" 
                                                                    min={0} 
                                                                    onChange={onChangeProducts} 
                                                                    id={index.varian}
                                                                    defaultValue={index.total}
                                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            );
                                        }
                                    })
                                    }
                                

                            
                                
                                

                                
                                
                                <div class="form-group">
                                    <div className="row" id="teksBanner">
                                        <div className="col-lg-12 pl-0">
                                            <h3>Dikirim Kepada</h3>
                                        </div>
                                        {dataCatalog.map((index) => (
                                            <div className="container-fluid">
                                                
                                                    <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="noReceipt"  
                                                                defaultValue={index.noReceipt}
                                                                onChange={targetNoReceipt} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="name"  
                                                                defaultValue={index.name} 
                                                                onChange={targetName} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="address"  
                                                                defaultValue={index.address}
                                                                onChange={targetAddress} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0 font-w100">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                onKeyPress={onKeyNumber} 
                                                                name="phone"
                                                                defaultValue={index.phone} 
                                                                onChange={targetPhone} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-10">
                                    <button onClick={(e) => editCatalog(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Simpan</button>
                                </div>
                            </form>
                        </div>

                            

                    </div>
                </div>
                
            </div>
            
        </div>
    );
}
export default EditCatalog;