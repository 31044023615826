import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ServicesPTS from "../../../Services/ServicesPTS";

const Status = ({ item }) => {
    const items = item;

    const [dept, setDept] = useState("");
    const [tempDetail, setTempDetail] = useState("");
    
    useEffect(() => {
        servicesDetail(items.id, items.type)
        var departemen = JSON.parse(localStorage.getItem("userPTS")).data[0].dept;
        setDept(departemen);
    },[items])

    const servicesDetail = async(id, modul) =>{
        var result;
        if(modul === 'banner'){
            result = await ServicesPTS.getSearchOrderBannerId(id);
            setTempDetail(result.data[0])
        }else if(modul === 'sample'){
            result = await ServicesPTS.getDetailMasterSample(id);
            setTempDetail(result.data[0])
        }else if(modul === 'catalog'){
            result = await ServicesPTS.getDetailCatalog(id);
            setTempDetail(result.data[0])
        }else if(modul === 'label'){
            result = await ServicesPTS.getDetailLabel(id);
            setTempDetail(result.data[0])
        }else{
            console.log('Error Services Detail');
        }
    }

    const submitApproved = (dataId, modul) => {
        var temp;

        if (dataId.approved === "1") {
            temp = "0";
            // setApproved(temp);
        } else if (dataId.approved === "0" || dataId.checked === null) {
            temp = "1";
            // setApproved(temp);
        }

        var data = {
            dataProId: dataId.dataProId,
            approved: temp,
        };
        
        if(modul === 'banner'){
            ServicesPTS.updateOrderBannerId(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else if(modul === 'sample'){
            ServicesPTS.editMasterSample(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else if(modul === 'catalog'){
            ServicesPTS.editCatalog(dataId.id, data)
            .then(response => {
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            })
        }else if(modul === 'label'){
            ServicesPTS.editLabel(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else{
            console.log('submitApproved Is ERROR');
        }
        
    }

    const submitChecked = (dataId, modul) => {
        var temp;

        if (dataId.checked === "1") {
            temp = "0";
            // setChecked(temp);
        } else if (dataId.checked === "0" || dataId.checked === null) {
            temp = "1";
            // setChecked(temp);
        }

        var data = {
            dataProId: dataId.dataProId,
            checked: temp,
        };
        
        if(modul === 'banner'){
            ServicesPTS.updateOrderBannerId(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else if(modul === 'sample'){
            ServicesPTS.editMasterSample(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else if(modul === 'catalog'){
            ServicesPTS.editCatalog(dataId.id, data)
            .then(response => {
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            })
        }else if(modul === 'label'){
            ServicesPTS.editLabel(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else{
            console.log('submitChecked Is ERROR');
        }
    }

    const submitPrepared = (dataId, modul) => {
        var temp;

        if (dataId.prepared === "1") {
            temp = "0";
            // setChecked(temp);
        } else if (dataId.prepared === "0" || dataId.checked === null) {
            temp = "1";
            // setChecked(temp);
        }

        var data = {
            dataProId: dataId.dataProId,
            prepared: temp,
        };
        
        if(modul === 'banner'){
            ServicesPTS.updateOrderBannerId(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else if(modul === 'sample'){
            ServicesPTS.editMasterSample(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else if(modul === 'catalog'){
            ServicesPTS.editCatalog(dataId.id, data)
            .then(response => {
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            })
        }else if(modul === 'label'){
            ServicesPTS.editLabel(dataId.id, data)
            .then((response) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
        }else{
            console.log('submitPrepared Is ERROR');
        }
    }

    const submitCheckboxApproved = (data, modul) =>{
        if(modul === 'banner'){
            submitApproved(data, modul)
        }else if(modul === 'sample'){
            submitApproved(data, modul)
        }else if(modul === 'catalog'){
            submitApproved(data, modul)
        }else if(modul === 'label'){
            submitApproved(data, modul);
        }else{
            console.log('submitCheckboxApproved Is ERROR');
        }
    }

    const submitCheckboxChecked = (data, modul) =>{
        if(modul === 'banner'){
            submitChecked(data, modul)
        }else if(modul === 'sample'){
            submitChecked(data, modul)
        }else if(modul === 'catalog'){
            submitChecked(data, modul)
        }else if(modul === 'label'){
            submitChecked(data, modul);
        }else{
            console.log('submitCheckboxApproved Is ERROR');
        }
    }

    const submitCheckboxPrepared = (data, modul) =>{
        if(modul === 'banner'){
            submitPrepared(data, modul)
        }else if(modul === 'sample'){
            submitPrepared(data, modul)
        }else if(modul === 'catalog'){
            submitPrepared(data, modul)
        }else if(modul === 'label'){
            submitPrepared(data, modul);
        }else{
            console.log('submitCheckboxPrepared Is ERROR');
        }
    }

    const handleChecklist = (data) => {
        var approved__ = data.approved ===  "1" ? 1 : 0;
        var checked__ = data.checked == "1" ? 1 : 0;
        var prepared__ = data.prepared == "1" ? 1 : 0;
        if(dept === "Admin"){
            var approved_ = data.approved === "1" ? 1 : 0;
            if(data.approved == "1"){
                return(
                    <>
                        <div id="checklist">
                            <input
                                disabled
                                type="checkbox"
                                className="checkboxSales"
                                checked={approved_}
                                id={`dept` + dept}
                                name={`dept` + dept}
                                onChange={() => {
                                    submitCheckboxApproved(data, items.type);
                                }}
                            />
                            <span className="span-checklist font-size12">Sales</span>
                        </div>
                        <div id="checklist">
                            <input
                                type="checkbox"
                                className="checkboxProduction"
                                checked={checked__}
                                disabled
                                id={`dept` + dept}
                                name={`dept` + dept}
                            />
                            <span className="span-checklist font-size12">Production</span>
                        </div>
                        <div id="checklist">
                            <input
                                type="checkbox"
                                className="checkboxStorage"
                                checked={prepared__}
                                id={`dept` + dept}
                                disabled
                                name={`dept` + dept}
                            />
                            <span className="span-checklist font-size12">Storage</span>
                        </div>
                    </>
                )
            }else{
                return(
                    <>
                        <div id="checklist">
                            <input
                                type="checkbox"
                                className="checkboxSales"
                                checked={approved_}
                                id={`dept` + dept}
                                name={`dept` + dept}
                                onClick={() => {
                                    submitCheckboxApproved(data, items.type);
                                }}
                            />
                            <span className="span-checklist font-size12">Sales</span>
                        </div>
                        <div id="checklist">
                            <input
                                type="checkbox"
                                className="checkboxProduction"
                                checked={checked__}
                                disabled
                                id={`dept` + dept}
                                name={`dept` + dept} 
                            />
                            <span className="span-checklist font-size12">Production</span>
                        </div>
                        <div id="checklist">
                            <input
                                type="checkbox"
                                className="checkboxStorage"
                                checked={prepared__}
                                id={`dept` + dept}
                                disabled
                                name={`dept` + dept}
                            />
                            <span className="span-checklist font-size12">Storage</span>
                        </div>
                    </>
                )
            }
        }else if(dept === "Production"){
            var checked_ = data.checked === "1" ? 1 : 0;
            if (data.checked === "0") {
                return (
                  <>
                    <div id="checklist">
                      <input
                        disabled
                        type="checkbox"
                        className="checkboxSales"
                        checked={approved__}
                        id={`dept` + dept}
                        name={`dept` + dept}
                      />
                      <span className="span-checklist font-size12">Sales</span>
                    </div>
                    <div id="checklist">
                      <input
                        disabled={data.approved == "0" ? 1 :0}
                        type="checkbox"
                        className="checkboxProduction"
                        checked={checked_}
                        id={`dept` + dept}
                        name={`dept` + dept}
                        onChange={() => {
                          submitCheckboxChecked(data, items.type);
                        }}
                      />
                      <span className="span-checklist font-size12">Production</span>
                    </div>
                    <div id="checklist">
                      <input
                        disabled
                        type="checkbox"
                        className="checkboxStorage"
                        checked={prepared__}
                        id={`dept` + dept}
                        name={`dept` + dept}
                      />
                      <span className="span-checklist font-size12">Storage</span>
                    </div>
                  </>
                );
            } else {
                return (
                  <>
                    <div id="checklist">
                      <input
                        disabled
                        type="checkbox"
                        className="checkboxSales"
                        checked={approved__}
                        id={`dept` + dept}
                        name={`dept` + dept}
                      />
                      <span className="span-checklist font-size12">Sales</span>
                    </div>
                    <div id="checklist">
                      <input
                        disabled={data.approved == "1" ? 0 : 1}
                        type="checkbox"
                        className="checkboxProduction"
                        checked={checked_}
                        id={`dept` + dept}
                        name={`dept` + dept}
                        onClick={() => {
                            submitCheckboxChecked(data, items.type);
                        }}
                      />
                      <span className="span-checklist font-size12">Production</span>
                    </div>
                    <div id="checklist">
                      <input
                        disabled
                        type="checkbox"
                        className="checkboxStorage"
                        checked={prepared__}
                        id={`dept` + dept}
                        name={`dept` + dept}
                      />
                      <span className="span-checklist font-size12">Storage</span>
                    </div>
                  </>
                );
            }
        }else if(dept === "Storage"){
            var prepared_ = data.prepared === "1" ? 1 : 0;
            if (data.checked === "0") {
                return (
                  <>
                    <div id="checklist">
                      <input
                        disabled
                        type="checkbox"
                        className="checkboxSales"
                        checked={approved__}
                        id={`dept` + dept}
                        name={`dept` + dept}
                      />
                      <span className="span-checklist font-size12">Sales</span>
                    </div>
                    <div id="checklist">
                          <input
                              type="checkbox"
                              className="checkboxProduction"
                              checked={checked__}
                              disabled
                              id={`dept` + dept}
                              name={`dept` + dept}
                          />
                          <span className="span-checklist font-size12">Production</span>
                    </div>
                    <div id="checklist">
                      <input
                        disabled={ data.checked == "0" ? 1 : 0 }
                        type="checkbox"
                        className="checkboxStorage"
                        checked={prepared_}
                        id={`dept` + dept}
                        name={`dept` + dept}
                        onChange={() => {
                          submitCheckboxPrepared(data, items.type);
                        }}
                      />
                      <span className="span-checklist font-size12">Storage</span>
                    </div>
                  </>
                );
            } else {
                return (
                  <>
                    <div id="checklist">
                        <input
                            disabled
                            type="checkbox"
                            className="checkboxSales"
                            checked={approved__}
                            id={`dept` + dept}
                            name={`dept` + dept}
                        />
                      <span className="span-checklist font-size12">Sales</span>
                    </div>
                    <div id="checklist">
                        <input
                            type="checkbox"
                            className="checkboxProduction"
                            checked={checked__}
                            disabled
                            id={`dept` + dept}
                            name={`dept` + dept}
                        />
                        <span className="span-checklist font-size12">Production</span>
                    </div>
                    <div id="checklist">
                        <input
                            disabled={data.checked == "1" ? 0 : 1 }
                            type="checkbox"
                            className="checkboxStorage"
                            checked={prepared_}
                            id={`dept` + dept}
                            name={`dept` + dept}
                            onClick={() => {
                                submitCheckboxPrepared(data, items.type);
                            }}
                        />
                      <span className="span-checklist font-size12">Storage</span>
                    </div>
                  </>
                );
            }
        }
    }

    return(
        <>
            {handleChecklist(tempDetail)}
        </>
    )
}
export default Status;