import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import servicesPTS from "../../../Services/ServicesPTS";
import { ChangeEvent } from "react";

// const BASE_URL = "http://localhost:8080"
const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = "http://192.168.3.26:8080"
const APP_URL_TEMP = "/static/pto/";

const optionsType = [
    {value: 'car', label: 'Car'},
    {value: 'motorcycle', label: 'Motorcycle'},
    {value: 'home interior', label: 'Home Interior'}
];

const optionsSize = [
    {value: [300, 100], label: '300Cm X 100Cm'},
    {value: [400, 100], label: '400Cm X 100Cm'},
    {value: [500, 100], label: '500Cm X 100Cm'},
    {value: [300, 75], label: '300Cm X 75Cm'},
    {value: [400, 75], label: '400Cm X 75Cm'},
    {value: [500, 75], label: '500Cm X 75Cm'},
];

const EditBanner = () => {
    const history = useHistory();
    const { id } = useParams();
    const urlImage = BASE_URL+APP_URL_TEMP;

    const [boxIsName, setBoxIsName] = useState("");
    const [boxIsTagline, setBoxIsTagline] = useState("");
    const [boxIsContact, setBoxIsContact] = useState("");

    const [listOrderId, setListOrderId] = useState([]);
    const [value, setValue] = useState("");
    const [updateOrderBanner, setUpdateOrderBanner] = useState([
        {
            dataProId: "",
            type: "",
            size: "",
            image: "",
            imagePreview: "",
            display_name: "",
            display_tagline: "",
            display_contact: "",
            
            // isName: 1,
            // defaultCheckName: true,
            // isTagline: 1,
            // defaultCheckTagline: true,
            // isContact: 1,
            // defaultCheckContact: true,


            recipient: "",
            delivery_address: "",
            phone: "",
        }
    ]);

    useEffect(() => {
        getOrderBannerId();
    },[]);

    const getOrderBannerId = () => {
        var ids = id;
        servicesPTS.getSearchOrderBannerId(ids)
            .then((response) =>{
                setListOrderId(response.data);
                setUpdateOrderBanner({
                    type: response.data[0].type,
                    size: response.data[0].size,
                    image: response.data[0].image,
                    display_name: response.data[0].display_name,
                    display_tagline: response.data[0].display_tagline,
                    display_contact: response.data[0].display_contact,
                    isName: response.data[0].isName,
                    isTagline: response.data[0].isTagline,
                    isContact: response.data[0].isContact,
                    recipient: response.data[0].recipient,
                    delivery_address: response.data[0].delivery_address,
                    phone: response.data[0].phone,
                });

                setBoxIsName(Number(response.data[0].isName) === 1 ? true : false);
                setBoxIsTagline(Number(response.data[0].isTagline) === 1 ? true : false);
                setBoxIsContact(Number(response.data[0].isContact) === 1 ? true : false);
            })
            .catch(e => {
                console.log(e);
            });
    }

    console.log('bukan',listOrderId);
    console.log('update',updateOrderBanner);
    console.log('check', boxIsName);
    
    const handleDataType = (e) => {
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{type: tar},
        }));
    }
    const handleDataSize = (e) => {
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{size: tar},
        }));
    }
    const handleDataImage = (e) => {
        const img = e.target.files[0];
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{image: img},
        }));
    }
    const handleDataNamaToko = (e) => {
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{display_name: tar},
        }));
    }
    const handleDataTaglineToko = (e) => {
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{display_tagline: tar},
        }));
    }
    const handleDataKontakToko = (e) => {
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{display_contact: tar},
        }));
    }

    const handleDataKirimNama = (e) =>{
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{recipient: tar},
        }));
    }
    const handleDataKirimAlamat = (e) => {
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{delivery_address: tar},
        }));
    }
    const handleDataKirimPhone = (e) => {
        const tar = e.target.value;
        setUpdateOrderBanner((prevState) => ({
                ...prevState,
                ...{phone: tar},
        }));
    }

    const handleCheckIsName = (e) => {
        const defaultCheckName = e.target.checked;
        if(defaultCheckName == true){
            setBoxIsName(Number(1));
        }else{
            setBoxIsName(Number(0));
        }
    }

    const handleCheckIsTagline = (e) =>{
        const defaultCheckTagline = e.target.checked;
        if(defaultCheckTagline == true){
            setBoxIsTagline(Number(1));
        }else{
            setBoxIsTagline(Number(0));
        }
    }

    const handleCheckIsContact = (e) => {
        const defaultCheckContact = e.target.checked;
        if(defaultCheckContact == true){
            setBoxIsContact(Number(1));
        }else{
            setBoxIsContact(Number(0));
        }
    }

    const onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const configurationImage = (w, h) => {
        const width = Number(w);
        const height = Number(h);
        var text =  'Bentuk Gambar';
        if(width === height){
            return(
                <div className="config fontFamily font-size14 text-center" 
                    style={{
                        width: 100, 
                        height: 100, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }else if(width <= height){
            return(
                <div className="config1 fontFamily font-size14 text-center" 
                    style={{
                        width: 100, 
                        height: 150, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }else if(width >= height){
            return(
                <div className="config2 fontFamily font-size14 text-center" 
                    style={{
                        width: 150, 
                        height: 100, 
                        backgroundColor: '#DFE6EA',
                        borderWidth:1,
                        borderStyle: 'dashed',
                        borderColor:'#C49F18',
                }}>
                    {text}
                </div>
            );
        }
    }

    const getUpdateBannerId = (e) => {
        e.preventDefault();
        var ids = id;
        var data = {
            type: updateOrderBanner.type,
            size: updateOrderBanner.size,
            // files: updateOrderBanner.image,
            
            display_name: updateOrderBanner.display_name,
            display_tagline: updateOrderBanner.display_tagline,
            display_contact: updateOrderBanner.display_contact,
            isName: boxIsName,
            isTagline: boxIsTagline,
            isContact: boxIsContact,
            
            recipient: updateOrderBanner.recipient,
            delivery_address: updateOrderBanner.delivery_address,
            phone: updateOrderBanner.phone,
        }
        console.log('data',data);
        servicesPTS.updateOrderBannerId(ids, data)
        .then(response => {
            getOrderBannerId();
            window.location.reload(false);
            // this.setState({
            //     // dataProId: response.data.dataProId,
            //     type: response.data.type,
            //     size: response.data.size,
            //     files: response.data.image,
                
            //     display_name: response.data.display_name,
            //     display_tagline: response.data.display_tagline,
            //     display_contact: response.data.display_contact,
            //     isName: response.data.isName,
            //     isTagline: response.data.isTagline,
            //     isContact: response.data.isContact,
                
            //     delivery_address: response.data.delivery_address,
            //     recipient: response.data.recipient,
            //     phone: response.data.phone,
            // });
        })
        .catch(e => {
            console.log(e);
        });
        
    }

    return(
        <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20 pb-10 mb-17per">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <Link to={{pathname:'/tool-support/banner'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span className="span-breadcrumb pl-15" id="satu">Order Banner</span>
                            </Link>
                            
                        </div>
                    </div>
                    <div className="row pl-20 color-1 fontFamily mb-10">
                        <div className="col-lg-12 pl-0" id="title-h1">
                            
                                {listOrderId.map((index) => (
                                    <h1>Edit Banner <span className="namaToko">{index.toko}</span></h1>
                                ))}
                            
                            
                        </div>
                        <div className="col-lg-12">
                            {listOrderId.map((index) => (
                            <form method="POST" encType="multipart/form-data" ref={form => form = form}>
                            
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="type">Tipe Shop Banner</label>
                                        </div>
                                        <div className="col-lg-3 col-xs-7 d-flex">
                                            <select defaultValue={index.type} className="form-control" onChange={handleDataType}>
                                                <option value="car">Car</option>
                                                <option value="motorcycle">Motorcycle</option>
                                                <option value="home interior">Home Interior</option>
                                            </select>
                                            {/* <input 
                                                type="text" 
                                                className="button-typeText" 
                                                id="formUkuran2" 
                                                name="type"
                                                defaultValue={index.type}
                                                // placeholder={}
                                                onChange={handleDataType} 
                                            /> */}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="size">Ukuran Shop Banner</label>
                                        </div>
                                        <div className="col-lg-3 col-xs-7 d-flex">
                                            <select defaultValue={index.size} onChange={handleDataSize} className="form-control">
                                                <option value="300,100">300Cm X 100Cm</option>
                                                <option value="400,100">400Cm X 100Cm</option>
                                                <option value="500,100">500Cm X 100Cm</option>

                                                <option value="300,75">300Cm X 75Cm</option>
                                                <option value="400,75">400Cm X 75Cm</option>
                                                <option value="500,75">500Cm X 75Cm</option>
                                            </select>
                                            {/* <input 
                                                type="text" 
                                                className="button-typeText" 
                                                id="formUkuran2" 
                                                name="size"  
                                                defaultValue={index.size} 
                                                 
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Foto</label>
                                        </div>
                                        <div className="col-lg-5">
                                            <div id="uploadFoto" className="d-grid mx-auto">
                                                <div className="padding-h10" id="previewFoto">
                                                    <div>
                                                        <img 
                                                            src={urlImage+index.image}
                                                            width="200"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row" id="teksBanner">
                                        <div className="col-lg-12 pl-0">
                                            <h3>Teks di Shop Banner</h3>
                                        </div>
                                        <div className="container-fluid">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama Toko</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <div className="checklist">

                                                            <input 
                                                                className="checkbox"
                                                                type="checkbox" 
                                                                id="topping" 
                                                                name="isName" 
                                                                checked={boxIsName}
                                                                onChange={(e) => {handleCheckIsName(e);}}
                                                            />
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            className="button-typeText ml-15" 
                                                            id="formUkuran2" 
                                                            name="display_name"
                                                            // disabled= {}
                                                            value={updateOrderBanner.display_name }
                                                            onChange={handleDataNamaToko}
                                                            disabled={boxIsName == 1 ? false : true}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Tagline</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <div className="checklist">

                                                            <input 
                                                                className="checkbox"
                                                                type="checkbox" 
                                                                id="topping" 
                                                                name="isTagline" 
                                                                checked={boxIsTagline}
                                                                onChange={(e) => {handleCheckIsTagline(e);}}
                                                            />
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            className="button-typeText ml-15" 
                                                            id="formUkuran2" 
                                                            name="display_tagline"  
                                                            value={updateOrderBanner.display_tagline}
                                                            onChange={handleDataTaglineToko}
                                                            disabled={boxIsTagline == 1 ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak/Alamat</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <div className="checklist">

                                                            <input 
                                                                className="checkbox"
                                                                type="checkbox" 
                                                                id="topping" 
                                                                name="isContact" 
                                                                // value=""
                                                                checked={boxIsContact}
                                                                onChange={(e) => {handleCheckIsContact(e);}}
                                                            />
                                                        </div>
                                                        <textarea 
                                                            rows="4"
                                                            className="button-typeTextarea ml-15" 
                                                            name="display_contact"  
                                                            value={updateOrderBanner.display_contact} 
                                                            onChange={handleDataKontakToko}
                                                            disabled={boxIsContact == 1 ? false : true}
                                                        >
                                                        </textarea>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group mb-0">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Dikirim Kepada</h3>
                                            </div>
                                            <div className="container-fluid">
                                                {/* <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="sendResi"  
                                                                // value={this.state.targetSendResi} 
                                                                // onChange={this.targetSendResi} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="recipient"  
                                                                defaultValue={index.recipient}
                                                                onChange={handleDataKirimNama} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="delivery_address"  
                                                                defaultValue={index.delivery_address}
                                                                onChange={handleDataKirimAlamat} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 font-w100">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                onKeyPress={onKeyNumber} 
                                                                name="phone"  
                                                                defaultValue={index.phone} 
                                                                onChange={handleDataKirimPhone} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                    <div className="col-lg-12">
                                        <button onClick={(e) => getUpdateBannerId(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Edit Shop Banner</button>
                                    </div>
                            
                            </form>
                            ))}
                                
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
    )
}
export default EditBanner