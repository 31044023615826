import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ServicesPTS from "../../Services/ServicesPTS";

const APP_URL_ORDER_STATUS = process.env.REACT_APP_APP_URL+'seatmaker-order/';

const ComponentStatus = ({ propsId, propsModul }) => {
    const [idSeatmaker] = useState(propsId);
    const [modulSeatmaker] = useState(propsModul);
    const [dataOrder, setDataOrder] = useState("");

    useEffect(()=>{
        tempParams(idSeatmaker, modulSeatmaker)
        
    },[]);


    const tempParams = (keyword, modul) => {
        if(modul === 'banner'){
            callTable(ServicesPTS.getSearchOrderBannerId(keyword));
        }else if(modul === 'sample'){
            callTable(ServicesPTS.getDetailMasterSample(keyword))
        }else if(modul === 'catalog'){
            callTable(ServicesPTS.getDetailCatalog(keyword))
        }else if(modul === 'label'){
            callTable(ServicesPTS.getDetailLabel(keyword))
        }
    }

    const callTable = async(modelTable)=>{
        const { data } = await modelTable;
        setDataOrder(data[0]);
    }

    const showProgress = () =>{
        const data = dataOrder;
        if(data.approved == 1 && data.checked == 1 && data.prepared == 1){
            return(
                <>
                    <div className="container-MB pl-20 pr-20 pt-15 pb-10">
                        <div className="checkboxTimelineBig mx-auto">
                            <input
                                disabled
                                type="checkbox"
                                className="timelineSuccess"
                            />
                        </div>
                        <div className="checkboxTimelineBigP mx-auto">
                            <p>Pesanan Berhasil!</p>
                            <p>Silahkan Menunggu kedatangan pesanan anda <br/>Nomor Resi :</p>
                            <div className="text-center">
                                <button
                                    onClick={() => {navigator.clipboard.writeText(data.noReceipt)}}
                                >
                                    <span>{data.noReceipt}</span>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                            <g clip-path="url(#clip0_339_145)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 2.5C10.587 2.5 9.95107 2.76339 9.48223 3.23223C9.01339 3.70107 8.75 4.33696 8.75 5V7.5H11.25V5H25V18.75H22.5V21.25H25C25.663 21.25 26.2989 20.9866 26.7678 20.5178C27.2366 20.0489 27.5 19.413 27.5 18.75V5C27.5 4.33696 27.2366 3.70107 26.7678 3.23223C26.2989 2.76339 25.663 2.5 25 2.5H11.25ZM5 8.75C4.33696 8.75 3.70107 9.01339 3.23223 9.48223C2.76339 9.95107 2.5 10.587 2.5 11.25V25C2.5 25.663 2.76339 26.2989 3.23223 26.7678C3.70107 27.2366 4.33696 27.5 5 27.5H18.75C19.413 27.5 20.0489 27.2366 20.5178 26.7678C20.9866 26.2989 21.25 25.663 21.25 25V11.25C21.25 10.587 20.9866 9.95107 20.5178 9.48223C20.0489 9.01339 19.413 8.75 18.75 8.75H5Z" fill="#5893FF"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_339_145">
                                                    <rect width="30" height="30" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            );
        }else{
            return false;
        }
    }

    const dataChecked  = (data) =>{
        if(data.approved == 0){
            return '...';
        }else if(data.approved == 1 && data.checked == 0){
            return 'Proses...';
        }else if(data.approved == 1 & data.checked == 1 ){
            return 'Berhasil';
        }
    }

    const dataPrepared  = (data) =>{
        if((data.approved == 0 && data.checked == 0) || (data.approved == 1 && data.checked == 0)){
            return '...';
        }else if(data.approved == 1 && data.checked == 1 && data.prepared == 0){
            return 'Proses...';
        }else if(data.approved == 1 & data.checked == 1 && data.prepared == 1){
            return 'Berhasil';
        }
    }

    return(
        <>
            <section id="write-timeline">
                <div className="container-MB pl-20 pr-20 pt-20 pb-10">
                    <div className="checkboxTimeline">
                        <input
                          disabled
                          type="checkbox"
                          className={dataOrder.approved == 1 ? 'timelineProgressOn' : 'timelineProgress'}
                        />
                        <label>
                            Order Diterima Tim MBtech
                        </label>
                    </div>
                    <div className="checkboxTimelineButton">
                        <div 
                            className={`${dataOrder.approved == 1 ? 'buttonTimelineSukses' : 'buttonTimelineProses'} pt-5` }
                        >
                            {dataOrder.approved == 1 ? 'Berhasil' : 'Proses...'}
                        </div>
                    </div>
                </div>

                <div className="container-MB pl-20 pr-20 pt-15 pb-10">
                    <div className="checkboxTimeline">
                        <input
                          disabled
                          type="checkbox"
                          className={dataOrder.checked == 1 ? 'timelineProgressOn' : 'timelineProgress'}
                        />
                        <label>
                            Proses Produksi
                        </label>
                    </div>
                    <div className="checkboxTimelineButton">
                        <div 
                            className={`${dataOrder.checked == 1 ? 'buttonTimelineSukses' : 'buttonTimelineProses'} pt-5` }
                        >
                            {dataChecked(dataOrder)}
                        </div>
                    </div>
                </div>
                
                <div className="container-MB pl-20 pr-20 pt-15 pb-10">
                    <div className="checkboxTimeline">
                        <input
                          disabled
                          type="checkbox"
                          className={dataOrder.prepared == 1 ? 'timelineProgressOn' : 'timelineProgress'}
                        />
                        <label>
                            Proses Pengiriman
                        </label>
                    </div>
                    <div className="checkboxTimelineButton">
                        <div 
                            className={`${dataOrder.prepared == 1 ? 'buttonTimelineSukses' : 'buttonTimelineProses'} pt-5` }
                        >
                            {dataPrepared(dataOrder)}
                        </div>
                    </div>
                </div>
                {showProgress()}
            </section>
            
        </>
    );
}
export default ComponentStatus;