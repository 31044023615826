import axios from "axios";



const x =  axios.create({
  // baseURL: "http://192.168.3.26:8080/api",
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://localhost:8080/api",
  headers: {
    "Content-type": "application/json"
  }
});
// console.log(x.defaults.baseURL);
export default x;
