import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $, { event } from 'jquery';
import Pagination from "@material-ui/lab/Pagination";
import { BrowserRouter as Router, Switch, Route, Link, BrowserRouter } from 'react-router-dom';

import "../../../assets/css/toolSupport.css";
// import "../../../assets/css/PTS/order.css";

import servicesPTS from "../../../components/PTS/ServicesPTS/ServicesPTS";
import { Button } from "react-bootstrap/lib/InputGroup";

import PTSLogin from "../../../views/PTSLogin";

class MasterSample extends Component {
    constructor(props) {
        super(props);        
        this.getAllMasterSample = this.getAllMasterSample.bind(this);
        this.viewDept = this.viewDept.bind(this);
        this.handleCheckboxSales = this.handleCheckboxSales.bind(this);
        this.state = {
            masterSampleData: [],
            deleteOrderId: "",
            currentUser: undefined,
            approved: "",
        };
    }
    componentDidMount() {
        this.getAllMasterSample();
        var item = JSON.parse(localStorage.getItem('userPTS')).data[0].dept;
        this.setState({
            currentUser: item,
        });
        // console.log(JSON.parse(item).data[0].id);
        // console.log(this.state.currentUser);
    }

    time_stamp(time){
        var time_stamp = new Date(time);
        var tanggal = ('0' + time_stamp.getUTCDate()).slice(-2);
        var bulan = ('0' + (time_stamp.getUTCMonth() + 1)).slice(-2);
        var tahun = time_stamp.getUTCFullYear();
        return(
            <>
                {tanggal+'/'+bulan+'/'+tahun}
            </>
        )
    }

    getAllMasterSample(){
        servicesPTS.getDataMasterSample()
            .then((response) => {
                this.setState(
                    {
                        masterSampleData: response.data,
                    },
                )
            })
            .catch(e => {
                console.log(e)
            })
    }

    deleteMS(id) {
        servicesPTS.deleteMasterSampleId(id)
          .then((response) => {
            window.location.reload();
            this.setState({ deleteOrderId: true });
          })
          .catch((e) => {
            console.log(e);
          });
    }

    handleCheckboxSales(data, name){
        // console.log(data.approved);
        if(data.approved === "1"){
            return(
                <div id="checklist">
                    {data.approved}
                    <input 
                        type="checkbox" 
                        class="checkbox"
                        defaultChecked={true}
                        id={name} 
                        name={name}
                        onChange={() => this.handleUpdateCheckbox(data.id, data.approved)}
                        // checked="1"
                        // disabled="false"
                    />
                </div>
            );
        }else{
            return(
                <div id="checklist">
                    {data.approved}
                    <input 
                        type="checkbox" 
                        class="checkbox" 
                        defaultChecked={false}
                        id={name}
                        name={name}
                        // checked="0"
                        // checked="false"
                        // disabled="false"
                    />
                </div>
            );
        }
    }

    // handleCheckbox(data, name){
    //     if(data.approved == "1"){
    //         return(
    //             <div id="checklist">
    //                 <input 
    //                     class="checkbox" 
    //                     type="checkbox" 
    //                     id="" 
    //                     name={name} 
    //                     checked="true"
    //                 />
    //             </div>
    //         );
    //     }else{
    //         return(
    //             <div id="checklist">
    //                 <input 
    //                     class="checkbox" 
    //                     type="checkbox" 
    //                     id="" 
    //                     name={name} 
    //                     checked="false"
    //                 />
    //             </div>
    //         );
    //     }
    // }

    handleUpdateCheckbox(id, data){
        if(data === "1"){
            this.setState({
                // ...prevState,
                approved: "0"
            },() => console.log(this.state.approved));
        }if(data === "0"){
            this.setState({
                // ...prevState,
                approved: "1"
            },() => console.log(this.state.approved));
        }
        // console.log('data',this.state.approved);
        console.log('id',id);
    }
    
    viewDept(departemen){
        if(departemen === "Admin"){
            return(
                <>
                    <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                        <div className="" id="breadcrumb">
                            <div className="padding-h15">
                                <a href="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                        <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                                <span className="span-breadcrumb pl-15">Master Sample</span>
                            </div>
                        </div>
                        <div className="row pl-40 color-1 fontFamily">

                            <div className="col-lg-6 pl-0" id="title-h1">
                                <h1>Pemesanan Master Sample</h1>
                            </div>
                            <div className="col-lg-6" id="title-h1">
                                <Link to={{pathname:'/tool-support/input-master-sample'}} className="col-lg-5 col-xs-12 btn buttonTambah float-right"><span className="badge-plug">+</span>Pesan Master Sample</Link>
                            </div>
                            <div className="col-lg-12">
                            <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Nama Seatmaker</th>
                                        <th>No Resi</th>
                                        <th>Nama</th>
                                        <th>Alamat Pengiriman</th>
                                        <th>No HP</th>
                                        <th>Pengajuan</th>
                                        <th>Status</th>
                                        <th>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.masterSampleData.dataOrder &&
                                                this.state.masterSampleData.dataOrder.map((item, i) => (
                                                    <tr>
                                                        <td key={item}>{i+1}</td>
                                                        <td>{item.toko}</td>
                                                        <td>{item.noReceipt}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{
                                                        this.time_stamp(item.created_at)}</td>
                                                        <td>
                                                            <div className="col-lg-12">
                                                                {this.handleCheckboxSales(item, 'statusSales')}
                                                            </div>
                                                            
                                                            {/* <div className="col-lg-12">
                                                                {this.handleCheckbox(item, 'statusProduction')}
                                                            </div>
                                                            
                                                            <div className="col-lg-12">
                                                                {this.handleCheckbox(item, 'statusStorage')}
                                                            </div> */}
                                                        </td>
                                                        <td>
                                                            <div className="col-lg-12 margin-h3">
                                                                <Link to={`/tool-support/master-sample/${item.id}`}
                                                                    className="btn btn-success btn-fill col-lg-12"
                                                                >
                                                                    Edit
                                                                </Link>

                                                                <div className="col-lg-12 margin-h3 pl-0 pr-0">

                                                                <a
                                                                    className="btn btn-danger btn-fill col-lg-12"
                                                                    href="#"
                                                                    onClick={() => {
                                                                        if (
                                                                        window.confirm(
                                                                            "Apa Kamu Yakin Ingin Menghapus Data Master Sample ini?"
                                                                        )
                                                                        )
                                                                        this.deleteMS(item.id);
                                                                    }}
                                                                >
                                                                    Hapus
                                                                </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </>
            )
        }else if(departemen === "Sales"){
            return (
                <>
                    <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                        <div className="" id="breadcrumb">
                            <div className="padding-h15">
                                <a href="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                        <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                                <span className="span-breadcrumb pl-15">Master Sample</span>
                            </div>
                        </div>
                        <div className="row pl-40 color-1 fontFamily">

                            <div className="col-lg-6 pl-0" id="title-h1">
                                <h1>Pemesanan Master Sample</h1>
                            </div>
                            <div className="col-lg-6" id="title-h1">
                                <Link to={{pathname:'/tool-support/input-master-sample'}} className="col-lg-5 col-xs-12 btn buttonTambah float-right"><span className="badge-plug">+</span>Pesan Master Sample</Link>
                            </div>
                            <div className="col-lg-12">
                            <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Nama Seatmaker</th>
                                        <th>No Resi</th>
                                        <th>Nama</th>
                                        <th>Alamat Pengiriman</th>
                                        <th>No HP</th>
                                        <th>Pengajuan</th>
                                        <th>Status</th>
                                        <th>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.masterSampleData.dataOrder &&
                                                this.state.masterSampleData.dataOrder.map((item, i) => (
                                                    <tr>
                                                        <td key={item}>{i+1}</td>
                                                        <td>{item.toko}</td>
                                                        <td>{item.noReceipt}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{this.time_stamp(item.created_at)}</td>
                                                        <td>{this.handleCheckbox(item.status, 'status')}</td>
                                                        <td>
                                                            <div className="col-lg-12 margin-h3">
                                                                <Link to={`/tool-support/master-sample/${item.id}`}
                                                                    className="btn btn-success btn-fill col-lg-12"
                                                                >
                                                                    Edit
                                                                </Link>

                                                                <div className="col-lg-12 margin-h3 pl-0 pr-0">

                                                                <a
                                                                    className="btn btn-danger btn-fill col-lg-12"
                                                                    href="#"
                                                                    onClick={() => {
                                                                        if (
                                                                        window.confirm(
                                                                            "Apa Kamu Yakin Ingin Menghapus Data Master Sample ini?"
                                                                        )
                                                                        )
                                                                        this.deleteMS(item.id);
                                                                    }}
                                                                >
                                                                    Hapus
                                                                </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </>
            );
        }else if(departemen === "Production"){
            return (<>Production</>);
        }else if(departemen === "Storage"){
            return (<>Storage</>);
        }else{
            return (<>Sorry Maintenance</>);
        }
    }
    render() {
        // console.log(this.props.match.path);
        const {
            currentUser,
            masterSampleData
        } = this.state;

        
        // this.setState({
            
        // });
        return (
            <div className="row">
                {this.viewDept(currentUser)}
                {/* {(() => {
                    if(currentUser = "Admin"){
                        return(
                            <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                                <div className="" id="breadcrumb">
                                    <div className="padding-h15">
                                        <a href="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                                <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </a>
                                        <span className="span-breadcrumb pl-15">Master Sample</span>
                                    </div>
                                </div>
                                <div className="row pl-40 color-1 fontFamily">

                                    <div className="col-lg-6 pl-0" id="title-h1">
                                        <h1>Pemesanan Master Sample</h1>
                                    </div>
                                    <div className="col-lg-6" id="title-h1">
                                        <Link to={{pathname:'/tool-support/input-master-sample'}} className="col-lg-5 col-xs-12 btn buttonTambah float-right"><span className="badge-plug">+</span>Pesan Master Sample</Link>
                                    </div>
                                    <div className="col-lg-12">
                                    <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Nama Seatmaker</th>
                                                <th>No Resi</th>
                                                <th>Nama</th>
                                                <th>Alamat Pengiriman</th>
                                                <th>No HP</th>
                                                <th>Pengajuan</th>
                                                <th>Status</th>
                                                <th>Aksi</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    masterSampleData.dataOrder &&
                                                        masterSampleData.dataOrder.map((item, i) => (
                                                            <tr>
                                                                <td key={item}>{i+1}</td>
                                                                <td>{item.toko}</td>
                                                                <td>{item.noReceipt}</td>
                                                                <td>{item.name}</td>
                                                                <td>{item.address}</td>
                                                                <td>{item.phone}</td>
                                                                <td>{this.time_stamp(item.created_at)}</td>
                                                                <td>{this.handleCheckbox(item.status, 'status')}</td>
                                                                <td>
                                                                    <div className="col-lg-12 margin-h3">
                                                                        <Link to={`/tool-support/master-sample/${item.id}`}
                                                                            className="btn btn-success btn-fill col-lg-12"
                                                                        >
                                                                            Edit
                                                                        </Link>

                                                                        <div className="col-lg-12 margin-h3 pl-0 pr-0">

                                                                        <a
                                                                            className="btn btn-danger btn-fill col-lg-12"
                                                                            href="#"
                                                                            onClick={() => {
                                                                                if (
                                                                                window.confirm(
                                                                                    "Apa Kamu Yakin Ingin Menghapus Data Master Sample ini?"
                                                                                )
                                                                                )
                                                                                this.deleteMS(item.id);
                                                                            }}
                                                                        >
                                                                            Hapus
                                                                        </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                        );
                    }else{
                        return (<div>tidak ada</div>);
                    }
                })} */}
                
                
            </div>
        );
    }
}
export default MasterSample;