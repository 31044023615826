import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $, { event } from 'jquery';
import Pagination from "@material-ui/lab/Pagination";
import { BrowserRouter as Router, Switch, Route, Link, BrowserRouter } from 'react-router-dom';

import "../../../../assets/css/toolSupport.css";
import "../../../../assets/css/PTS/order.css";

import servicesPTS from "../../../PTS-Master/Services/ServicesPTS";
import { Button } from "react-bootstrap/lib/InputGroup";

class Catalog extends Component {
    constructor(props) {
        super(props);        
        this.getAllCatalog = this.getAllCatalog.bind(this);
        
        this.state = {
            dataCatalog: [],
            deleteOrderId: "",
        };
    }

    componentDidMount() {
        this.getAllCatalog();
    }

    

    getAllCatalog(){
        servicesPTS.getAllCatalog()
            .then((response) => {
                this.setState(
                    {
                        dataCatalog: response.data,
                    },
                )
            })
            .catch(e => {
                console.log(e)
            })
    }

    time_stamp(time){
        var time_stamp = new Date(time);
        var tanggal = ('0' + time_stamp.getUTCDate()).slice(-2);
        var bulan = ('0' + (time_stamp.getUTCMonth() + 1)).slice(-2);
        var tahun = time_stamp.getUTCFullYear();
        return(
            <>
                {tanggal+'/'+bulan+'/'+tahun}
            </>
        )
    }

    

    deleteCatalog(id){
        servicesPTS.deleteCatalog(id)
            .then((response) => {
                window.location.reload();
                this.setState({ deleteOrderId: true });
            })
            .catch((e) => {
                console.log(e)
            })
    }
    
    
    render() {
        // console.log(this.dataTersimpan('kirim'))
        const {
            dataCatalog
        } = this.state;
        console.log(this.props.match.path);
        // console.log(this.state.kirim);
        // const kiriman = this.props.history.location.state.kirim;
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <span className="span-breadcrumb pl-15">Order Banner</span>
                        </div>
                    </div>
                    {/* {this.dataTersimpan(kiriman)} */}
                    
                    <div className="row pl-40 color-1 fontFamily">

                        <div className="col-lg-6 pl-0" id="title-h1">
                            <h1>Pemesanan Catalog</h1>
                        </div>
                        <div className="col-lg-6" id="title-h1">
                            <Link 
                                to={{pathname:'/tool-support/input-catalog'}} 
                                className="col-lg-5 col-xs-12 btn buttonTambah float-right"
                            >
                                <span className="badge-plug">+</span>Pesan Catalog
                            </Link>
                        </div>
                        <div className="col-lg-12">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Nama Seatmaker</th>
                                        <th>No Resi</th>
                                        <th>Nama</th>
                                        <th>Alamat Pengiriman</th>
                                        <th>No HP</th>
                                        <th>Pengajuan</th>
                                        <th>Status</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    dataCatalog.dataOrder &&
                                        dataCatalog.dataOrder.map((item, i) => (
                                            <tr>
                                                <td key={item}>{i+1}</td>
                                                <td>{item.toko}</td>
                                                <td>{item.noReceipt}</td>
                                                <td>{item.name}</td>
                                                <td>{item.address}</td>
                                                <td>{item.phone}</td>
                                                <td>{this.time_stamp(item.created_at)}</td>
                                                <td>{item.status}</td>
                                                <td>
                                                    <div className="col-lg-12 margin-h3">
                                                        <Link to={`/tool-support/catalog/${item.id}`}
                                                            className="btn btn-success btn-fill col-lg-12"
                                                        >
                                                            Edit
                                                        </Link>

                                                        <div className="col-lg-12 margin-h3 pl-0 pr-0">

                                                        <a
                                                            className="btn btn-danger btn-fill col-lg-12"
                                                            href="#"
                                                            onClick={() => {
                                                                if (
                                                                window.confirm(
                                                                    "Apa Kamu Yakin Ingin Menghapus Data Catalog ini?"
                                                                )
                                                                )
                                                                this.deleteCatalog(item.id);
                                                            }}
                                                        >
                                                            Hapus
                                                        </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </table>

                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default Catalog;