import React, { Component} from "react";
import ReactDOM from 'react-dom';
// import $, { event } from 'jquery';
import {
    BrowserRouter as Router,
    Link,
    // Route,
    // Routes,
    // useParams,
  } from "react-router-dom";
  
// import Pagination from "@material-ui/lab/Pagination";
import "../../../../assets/css/toolSupport.css";
import "../../../../assets/css/PTS/order.css";

import servicesPTS from "../../../../components/PTS/ServicesPTS/ServicesPTS";

class EditCatalog extends Component {
    
    constructor(props) {
        super(props);     
        
        
        this.state = {
            id: props.match.params.id,
            // MS = Master Sample
            dataCatalog: [],
            filterCatalog:{
                dataProId:"",
                sendTo: "",
                noReceipt: "",
                name: "",
                address: "",
                phone: "",
                toko:""
            },
            updateCatalog:{
                dataProId:"",
                sendTo: "",
                noReceipt: "",
                name: "",
                address: "",
                phone: "",
                product:""
            },
            products: [],

            penjumlahan: 0,
            
        };

        this.getCatalogId = this.getCatalogId.bind(this);
        // this.gettan = this.gettan.bind(this);
        
        this.onChangeProducts = this.onChangeProducts.bind(this);
        // this.funcAddition = this.funcAddition.bind(this);

        this.targetPhone = this.targetPhone.bind(this);
        this.targetAddress = this.targetAddress.bind(this);
        this.targetName = this.targetName.bind(this);
        this.targetNoReceipt = this.targetNoReceipt.bind(this);

        
        this.editCatalog = this.editCatalog.bind(this);
        
    }

    componentDidMount() {  
        this.getCatalogId();
    }

   
    getCatalogId(){
        var id = this.state.id;
        servicesPTS.getDetailCatalog(id)
            .then((response) => {
                console.log('check',response.data[0])
                this.setState({
                    dataCatalog: response.data,
                    filterCatalog:{
                        dataProId: response.data[0].dataProId,
                        noReceipt: response.data[0].noReceipt,
                        name: response.data[0].name,
                        address: response.data[0].address,
                        phone: response.data[0].phone,
                        toko: response.data[0].toko,
                    },

                    updateCatalog:{
                        dataProId: response.data[0].dataProId,
                        noReceipt: response.data[0].noReceipt,
                        name: response.data[0].name,
                        address: response.data[0].address,
                        phone: response.data[0].phone,
                        product: response.data[0].product,
                    },
                    products: response.data[0].product,
                });
                
            })
            .catch(e =>{
                console.log(e);
            });
    }
    

    onChangeProducts(e){
        const products = this.state.products;
        const val = e.target.value;
        var id = e.target.id;
        var rest = [];
        if(id == 'Catalog Book'){
            
            rest = 
                {
                    varian: id,
                    total: Number(0),
                    color: val,
                }
            
        }else{
            
            rest = 
                {
                    varian: id,
                    total: Number(val),
                    color: "",
                }
        }
        products.push({
            ...rest,
        });

        const result = [...new Map(products.map(o => [o.varian, o])).values()];
        this.setState((prevState) => ({
            ...prevState,
            products : result
        }));
        
    }   

    targetPhone(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            filterCatalog:{
                ...prevState.filterCatalog,
                phone: tar,
            }
        }));
    }
    targetAddress(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            filterCatalog:{
                ...prevState.filterCatalog,
                address: tar,
            }
        }));
    }
    targetName(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            filterCatalog:{
                ...prevState.filterCatalog,
                name: tar,
            }
        }));
    }
    targetNoReceipt(e){
        const tar = e.target.value;
        this.setState((prevState) => ({
            filterCatalog:{
                ...prevState.filterCatalog,
                noReceipt: tar,
            }
        }));
    }
    

    
    onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    editCatalog(e){
        e.preventDefault();
        var id = this.state.id;
        var products = [...new Map(this.state.products.map(o => [o.varian, o])).values()]
        
        console.log(this.state.updateCatalog.product);
        var data = {
            dataProId: this.state.filterCatalog.dataProId,
            sendTo: this.state.filterCatalog.sendTo,
            noReceipt: this.state.filterCatalog.noReceipt,
            name: this.state.filterCatalog.name,
            address: this.state.filterCatalog.address,
            phone: this.state.filterCatalog.phone,
            product: products,
        }
        this.setState(
            {
                data
            }
        )
        servicesPTS.editCatalog(id, data)
        .then(response => {
            this.getCatalogId();
        })
        .catch(e => {
            console.log(e);
        })
        
    }


    render() {
        const {
            id,
            total,
            products,
            dataCatalog,
            updateMS
        } = this.state;
        // const products = product.
        // console.log('dataMS',dataMS);
        // console.log('updateMS', updateMS);
        return (
            
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <span className="span-breadcrumb pl-15">Catalog</span>
                            {/* <span className="span-breadcrumb pl-15">/ Input Master Sample </span> */}
                        </div>
                    </div>
                    <div className="row pl-40 color-1 fontFamily">
                        <div className="col-lg-12 pl-0" id="title-h1">
                            {dataCatalog.map((index) => (
                                <h1>Edit Catalog <span className="namaToko">{index.toko}</span></h1>
                            ))}
                            
                        </div>
                        <div className="col-lg-12">
                            <div className="row">
                                {/* <form method="POST" encType="multipart/form-data" ref={form => this.form = form}> */}
                                <form className="col-lg-12" method="POST" encType="multipart/form-data" ref={form => this.form = form}>
                                    
                                    {products &&
                                        products.map((index,i) =>{
                                            if(index.varian === 'Catalog Book'){
                                                return(
                                                    <div key={i} class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for={index.varian}>{index.varian}</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <div className="bg-jumlah d-flex" for={index.varian}>
                                                                    <textarea 
                                                                        id={index.varian}  
                                                                        className=""
                                                                        rows="4" 
                                                                        cols="29"
                                                                        defaultValue={index.color}
                                                                        onChange={this.onChangeProducts}
                                                                        placeholder="Catalog Campuran / Bundle"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }else if(index.varian !== 'Catalog Book'){
                                                return(
                                                    <div key={i} class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily">{index.varian}</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <div className="bg-jumlah " for={index.varian}>
                                                                    <label for={index.varian}>Jumlah</label>
                                                                    <input 
                                                                        type="number" 
                                                                        min={0} 
                                                                        onChange={this.onChangeProducts} 
                                                                        id={index.varian}
                                                                        defaultValue={index.total}
                                                                        className="ml-15 button-typeText text-effect"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                );
                                            }
                                        })
                                     }
                                    

                                
                                    
                                    

                                   
                                    
                                    <div class="form-group">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Dikirim Kepada</h3>
                                            </div>
                                            {dataCatalog.map((index) => (
                                                <div className="container-fluid">
                                                    
                                                        <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    name="noReceipt"  
                                                                    defaultValue={index.noReceipt}
                                                                    onChange={this.targetNoReceipt} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    name="name"  
                                                                    defaultValue={index.name} 
                                                                    onChange={this.targetName} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    name="address"  
                                                                    defaultValue={index.address}
                                                                    onChange={this.targetAddress} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-2 pl-0 font-w100">
                                                                <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                            </div>
                                                            <div className="col-lg-10 d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="button-typeText" 
                                                                    id="formUkuran2" 
                                                                    onKeyPress={this.onKeyNumber} 
                                                                    name="phone"
                                                                    defaultValue={index.phone} 
                                                                    onChange={this.targetPhone} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <button onClick={(e) => this.editCatalog(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Simpan</button>
                                    </div>
                                </form>
                            </div>

                                

                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default EditCatalog;