import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BrowserRouter, Switch, Route, } from 'react-router-dom';

// import MasterSample from "../../PTS/Pages/MasterSample";
import Dashboard from "../Pages/dashboard/Dashboard";
import MasterSample from "../Pages/masterSample/masterSample/MasterSample";
import MasterSampleInput from "../Pages/masterSample/create-masterSample/MasterSampleInput";
import EditMasterSample from "../Pages/masterSample/edit-masterSample/EditMasterSample";

import Catalog from "../Pages/catalog/catalog/Catalog";
import CatalogInput from "../Pages/catalog/createCatalog/CatalogInput";
import EditCatalog from "../Pages/catalog/edit-catalog/EditCatalog";

import Label from "../Pages/label/label/Label";
import LabelInput from "../Pages/label/createLabel/LabelInput";
import EditLabel from "../Pages/label/edit-label/EditLabel";

import Banner from "../Pages/banner/banner/Banner";
import BannerInput from "../Pages/banner/createBanner/BannerInput";
import EditBanner from "../Pages/banner/edit-banner/EditBanner";


const RoutesPTS = () =>{
    const history = useHistory();
    const baseURL = "/tool-support";

    useEffect(() => {
        // return history.listen((location) => {
        //     console.log(`You changed the page to: ${location.pathname}`)
        // })
    },[history]);

    return(
        <>
            
                <Switch>
                    <Route path={baseURL} exact component={Dashboard}/>

                    <Route path={baseURL+"/banner"} exact component={Banner}/>
                    <Route path={baseURL+"/banner/input"} component={BannerInput}/>
                    <Route path={baseURL+"/banner/:id"} component={EditBanner}/>

                    <Route path={baseURL+"/master-sample"} exact component={MasterSample}/>
                    <Route path={baseURL+"/master-sample/input"} component={MasterSampleInput}/>
                    <Route path={baseURL+"/master-sample/:id"} component={EditMasterSample} />

                    <Route path={baseURL+"/catalog"} exact component={Catalog}/>
                    <Route path={baseURL+"/catalog/input"} component={CatalogInput}/>
                    <Route path={baseURL+"/catalog/:id"} component={EditCatalog} />
                
                    <Route path={baseURL+"/label"} exact component={Label}/>
                    <Route path={baseURL+"/label/input"}  component={LabelInput}/>
                    <Route path={baseURL+"/label/:id"} component={EditLabel} />

                    
                </Switch>
            
        </>
    );
}
export default RoutesPTS;