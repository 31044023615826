import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import '../assets/css/PTS/viewReportSeatmaker.css';
import images_logo from '../assets/img/logo_mbtech.png';
import ComponentStatus from "../components/PTS-Master/Components/componentStatus/ComponentStatus";

const StatusSeatmaker = () => {
    const { keywords, modulus } = useParams();

    useEffect(()=>{
        document.body.style.backgroundColor = "#1d1d1d";
    },[])

    return(
        <div className="body-view-report-sm">
            <div className="container-body-report">
                <section id="header-top">
                    <div className="container-MB pt-20 pl-20 pr-20 pb-20">
                        <div className="">
                            <img width={"60px"} className="img-fluid" src={images_logo}></img>
                        </div>
                        <div className="grid-item-text-logo">
                            Proses Pesanan Anda
                        </div>
                    </div>
                    
                </section>
                <section id="timeline-tracking-no">
                    <div className="container-MB pl-20 pr-20 pt-15 pb-15">
                        <div className="grid-timeline">
                            Tracking No : #{keywords}
                        </div>
                    </div>
                </section>
                <section id="timeline-body">
                    <div className="container-MB pl-20 pr-20 pt-15">

                        <div className="progress-now">
                            <label>Proses Saat Ini </label>
                        </div>
                        
                    </div>
                </section>
                <ComponentStatus
                    propsId={keywords}
                    propsModul={modulus}
                />
            </div>

        </div>
    );
}
export default StatusSeatmaker;