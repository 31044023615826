import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $, { event } from 'jquery';
import "../../../assets/css/toolSupport.css";

class bodyDashboard extends Component {

    render() {
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="row pl-40 color-1 fontFamily">
                        <div className="col-lg-6 pl-0" id="title-h1">
                            <h1>Dashboard</h1>
                        </div>
                        
                        <div className="col-lg-12">                        

                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default bodyDashboard;