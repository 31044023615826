import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import './toPrint.css';

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { value } = props;
  return (
    <div className="print-source" ref={ref}>
      {value}
    </div>
  );
});
  
const ComponentToPrintWrapper = ({ item }) => {
  const componentRef = useRef();
  return (
    <>
      <ReactToPrint
        trigger={() => 
          <button  
            className="col-lg-2 col-xs-3 pl-0 pr-0 pt-0 pb-0" type="primary"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M20.5762 7.42188H19.1406V3.90625C19.1406 3.80265 19.0995 3.70329 19.0262 3.63004C18.953 3.55678 18.8536 3.51562 18.75 3.51562H6.25C6.1464 3.51562 6.04704 3.55678 5.97379 3.63004C5.90053 3.70329 5.85938 3.80265 5.85938 3.90625V7.42188H4.42383C3.28125 7.42188 2.34375 8.30078 2.34375 9.375V17.1875C2.34375 17.2911 2.38491 17.3905 2.45816 17.4637C2.53142 17.537 2.63077 17.5781 2.73438 17.5781H5.85938V21.4844C5.85938 21.588 5.90053 21.6873 5.97379 21.7606C6.04704 21.8338 6.1464 21.875 6.25 21.875H18.75C18.8536 21.875 18.953 21.8338 19.0262 21.7606C19.0995 21.6873 19.1406 21.588 19.1406 21.4844V17.5781H22.2656C22.3692 17.5781 22.4686 17.537 22.5418 17.4637C22.6151 17.3905 22.6562 17.2911 22.6562 17.1875V9.375C22.6562 8.30078 21.7187 7.42188 20.5762 7.42188ZM6.64062 4.29688H18.3594V7.42188H6.64062V4.29688ZM18.3594 21.0938H6.64062V15.2344H18.3594V21.0938ZM21.875 16.7969H19.1406V14.8438C19.1406 14.7401 19.0995 14.6408 19.0262 14.5675C18.953 14.4943 18.8536 14.4531 18.75 14.4531H6.25C6.1464 14.4531 6.04704 14.4943 5.97379 14.5675C5.90053 14.6408 5.85938 14.7401 5.85938 14.8438V16.7969H3.125V9.375C3.125 8.73047 3.71094 8.20312 4.42383 8.20312H20.5762C21.2891 8.20312 21.875 8.73047 21.875 9.375V16.7969ZM19.1406 11.3281C19.1406 11.4826 19.0948 11.6337 19.009 11.7622C18.9231 11.8906 18.8011 11.9908 18.6583 12.0499C18.5156 12.109 18.3585 12.1245 18.207 12.0944C18.0554 12.0642 17.9162 11.9898 17.8069 11.8806C17.6977 11.7713 17.6233 11.6321 17.5931 11.4805C17.563 11.329 17.5785 11.1719 17.6376 11.0292C17.6967 10.8864 17.7969 10.7644 17.9253 10.6785C18.0538 10.5927 18.2049 10.5469 18.3594 10.5469C18.5666 10.5469 18.7653 10.6292 18.9118 10.7757C19.0583 10.9222 19.1406 11.1209 19.1406 11.3281Z" 
                    fill="#363839"/>
            </svg>
          </button>
        }
        content={() => componentRef.current}
      />
      <div style={{display:'none'}}>
        <ComponentToPrint ref={componentRef} value={item} />
      </div>
    </>
  );
};
export default ComponentToPrintWrapper;