import React, { Component } from "react";
import { Grid, Row, Col, Modal } from "react-bootstrap";
import Card from "../components/Card/Card.jsx";
import Navbars from "../components/Navbars/Navbars";
import Sidebar from "../components/Sidebar/Sidebar";
import SeatmakerDataService from "../services/smaker.service";
import routes from "../routes.js";
import ImageEditor from "@toast-ui/react-image-editor";
import ReactPlayer from "react-player";
import "tui-image-editor/dist/tui-image-editor.css";
import "react-awesome-lightbox/build/style.css";
import "../assets/css/spinner.css";
import { Link } from "react-router-dom";


const download = require("downloadjs");
const APP_URL_TEMP = process.env.REACT_APP_API_URL+'static/tmp/';
const APP_URL_UPLOADS = process.env.REACT_APP_API_URL+'static/uploads/';

export default class ApplicationId extends Component {
  editorRef = React.createRef();
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.getSeatmaker = this.getSeatmaker.bind(this);
    this.updatePublished = this.updatePublished.bind(this);
    this.updateSeatmaker = this.updateSeatmaker.bind(this);

    this.state = {
      currentSeatmaker: {
        id: null,
        toko: "",
        alamat: "",
        deleteSuccess: false,
        acceptSuccess: false,
        pendingSuccess: false,
        published: false,
      },
      imageBrightness: 0,
      message: "",
      saveSpinner: false,
      showModal: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.getSeatmaker(this.props.match.params.id);
    if (window.innerWidth <= 720) {
      this.setState({ isMobile: true });
    }
  }
  onChangeTitle(e) {
    const title = e.target.value;

    this.setState(function (prevState) {
      return {
        currentSeatmaker: {
          ...prevState.currentSeatmaker,
          title: title,
        },
      };
    });
  }

  onChangeDescription(e) {
    const description = e.target.value;

    this.setState((prevState) => ({
      currentSeatmaker: {
        ...prevState.currentSeatmaker,
        description: description,
      },
    }));
  }

  getSeatmaker(id) {
    SeatmakerDataService.getProfile(id)
      .then((response) => {
        this.setState({
          currentSeatmaker: response.data,
        });
        // console.log(this.state.currentSeatmaker.id);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updatePublished(status) {
    var data = {
      id: this.state.currentSeatmaker.id,
      toko: this.state.currentSeatmaker.toko,
      alamat: this.state.currentSeatmaker.alamat,
      kota: this.state.currentSeatmaker.kota,
      published: status,
    };

    SeatmakerDataService.update(this.state.currentSeatmaker.id, data)
      .then((response) => {
        this.setState((prevState) => ({
          currentSeatmaker: {
            ...prevState.currentSeatmaker,
            published: status,
          },
        }));
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateSeatmaker() {
    SeatmakerDataService.update(
      this.state.currentSeatmaker.id,
      this.state.currentSeatmaker
    )
      .then((response) => {
        console.log(response.data);
        this.setState({
          message: "The seatmaker was updated successfully!",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  }

  deleteImage(id) {
    SeatmakerDataService.deleteimage(id)
      .then((response) => {
        // console.log(response.data);
        // this.props.history.push('/seatmaker')
        this.setState({ deleteSuccess: true });
        this.getSeatmaker(this.state.currentSeatmaker.id);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  editImage(image) {
    this.setState({
      showModal: true,
      imageName: image,
    });
  }

  downloadImage(image) {
    const editorInstance = this.editorRef.current.getInstance();
    // const editorInstance = this.imageEditor.current
    //   .getRootElement()
    //   .classList.add("image-editor-root");

    const data = editorInstance.toDataURL();
    console.log(data);
    if (data) {
      const mimeType = data.split(";")[0];
      const extension = data.split(";")[0].split("/")[1];
      download(data, image, mimeType);
    }
  }

  saveImage(image) {
    this.setState({ saveSpinner: true });
    const editorInstance = this.editorRef.current.getInstance();
    const data = editorInstance.toDataURL();
    var blob = this.dataURItoBlob(data);
    const file = new File([blob], `edited-${Date.now()}-` + image, {
      type: "image/jpeg",
      lastModified: new Date(),
    });

    SeatmakerDataService.upload(
      file,
      this.state.currentSeatmaker.id,
      (event) => {}
    )
      .then((response) => {
        this.setState({ saveSpinner: false });
        this.setState((prev) => {
          let nextMessage = [...prev.message, "File successfully Saved: "];
          return {
            message: nextMessage,
          };
        });
        this.infoSeatmaker();
        return SeatmakerDataService.getFiles();
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
      })
      .catch(() => {
        this.setState((prev) => {
          let nextMessage = [...prev.message, "Could not save the file: "];
          return {
            message: nextMessage,
          };
        });
      });
  }

  acceptImage(id, status) {
    var data = {
      status: status,
    };
    SeatmakerDataService.acceptimage(id, data)
      .then((response) => {
        console.log(response.data);
        // this.props.history.push('/seatmaker')
        this.setState((prevState) => ({
          currentSeatmaker: {
            ...prevState.currentSeatmaker,
            published: 2,
          },
        }));
        this.setState({ acceptSuccess: true });
      })
      .catch((e) => {
        console.log(e);
      });
    setTimeout(this.getSeatmaker(this.state.currentSeatmaker.id), 3000);
  }

  // unpublishImage(id) {
  // SeatmakerDataService.holdimage(id)
  //   .then(response => {
  //     console.log(response.data);
  //     // this.props.history.push('/seatmaker')
  //       this.setState({acceptSuccess: true})
  //   })
  //   .catch(e => {
  //     console.log(e);
  //   });
  // }

  brightnessImage(status, value) {
    const editorInstance = this.editorRef.current.getInstance();
    if (status === "up") {
      editorInstance.applyFilter("brightness", {
        brightness: parseInt(this.state.imageBrightness + value, 10) / 255,
      });
      const countBrightness = this.state.imageBrightness + value;
      this.setState({ imageBrightness: countBrightness });
    } else if (status === "down") {
      editorInstance.applyFilter("brightness", {
        brightness: parseInt(this.state.imageBrightness - value, 10) / 255,
      });
      const countBrightness = this.state.imageBrightness - value;
      this.setState({ imageBrightness: countBrightness });
    }
  }

  render() {
    if (this.state.isMobile) {
      require("../assets/css/tui-image-editor-mobile.css");
    }
    const { currentSeatmaker } = this.state;
    const myTheme = {
      "common.bi.image": "https://mbtech.info/asset/img/logo/MBtech.png",
      "common.bisize.width": "55px",
      "common.bisize.height": "35px",
      "common.backgroundColor": "#fff",
      "common.border": "1px solid #c1c1c1",
      "header.display": "none",

      // "submenu.backgroundColor": "#fff",
      // "submenu.partition.color": "#fff",
      // "common.background":
      //   "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAABemlDQ1BJQ0MgUHJvZmlsZQAAKM9jYGAqSSwoyGFhYGDIzSspCnJ3UoiIjFJgv8PAzcDDIMRgxSCemFxc4BgQ4MOAE3y7xsAIoi/rgsxK8/x506a1fP4WNq+ZclYlOgz4AXdKanEyAwMjB5CdnFKcnAtk5wDZOskFRSVA9gwgW7e8pADEPgFkixQBHQhk3wGx0yHsDyB2EpjNxAJWExLkDGRLANkCSRC2BoidDmFbgNjJGYkpQLYHyC6IG8CA08NFwdzAUteRgcogN6cUZgcotHhS80KDQe4AYhkGDwYXBgUGcwYDBksGXQbHktSKEpBC5/yCyqLM9IwSBUdgyKYqOOfnFpSWpBbpKHjmJevpKBgZGBqA1IHiDGL05yCw6Yxi5xFi+QsZGCyVGRiYexBiSdMYGLbvYWCQOIUQU5nHwMBvzcCw7VxBYlEi3OGM31gI8YvTjI0gbB4nBgbWe///f1ZjYGCfxMDwd+L//78X/f//dzHQfmCcHcgBACR3aeD10IV6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABnGlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczpleGlmPSJodHRwOi8vbnMuYWRvYmUuY29tL2V4aWYvMS4wLyI+CiAgICAgICAgIDxleGlmOlBpeGVsWERpbWVuc2lvbj4xMTA8L2V4aWY6UGl4ZWxYRGltZW5zaW9uPgogICAgICAgICA8ZXhpZjpQaXhlbFlEaW1lbnNpb24+OTk8L2V4aWY6UGl4ZWxZRGltZW5zaW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KydpWSQAAACVJREFUKFNj/Pnz538GIgFYMRsbG5SLHzBBaaLAqGJkQCvFDAwAlKIH+ycXcc8AAAAASUVORK5CYII=)",
    };
    return (
      <div className="wrapper">
        <Sidebar {...this.props} routes={routes} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <Navbars />
          <div className="content">
            {/* <nav aria-label="breadcrumb" role="navigation">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/application">application</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Current Page</li>
            </ol>
          </nav> */}
            <Grid fluid>
              <Row>
                {this.state.acceptSuccess ? (
                  <div
                    role="alert"
                    className="alert alert-success"
                    id="alert-success"
                  >
                    {/* <button type="button" aria-hidden="true" className="close">✕</button> */}
                    <span>
                      <b> Sukses - </b> Foto Berhasil ditambahkan.
                    </span>
                  </div>
                ) : null}
                {this.state.deleteSuccess ? (
                  <div
                    role="alert"
                    className="alert alert-danger"
                    id="alert-info"
                  >
                    {/* <button type="button" aria-hidden="true" className="close">✕</button> */}
                    <span>
                      <b> Info - </b> Foto Berhasil dihapus.
                    </span>
                  </div>
                ) : null}
                <Col md={12}>
                  <Card
                    title="Review Gallery Seat Maker"
                    content={
                      <div>
                        {currentSeatmaker ? (
                          <div className="edit-form">
                            <form>
                              <div className="form-group">
                                <label htmlFor="title">Toko</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  value={currentSeatmaker.toko}
                                  onChange={this.onChangeTitle}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="description">Alamat</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="description"
                                  value={currentSeatmaker.alamat}
                                  onChange={this.onChangeDescription}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="description">Kota</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="description"
                                  value={currentSeatmaker.kota}
                                  onChange={this.onChangeDescription}
                                />
                              </div>

                              <div className="form-group">
                                <Modal
                                  show={this.state.showModal}
                                  style={
                                    this.state.isMobile
                                      ? style.modalm
                                      : style.modal
                                  }
                                  enforceFocus={false}
                                >
                                  {this.state.saveSpinner ? (
                                    <div className="loading">Saving&#8230;</div>
                                  ) : null}
                                  <div
                                    className="container"
                                    style={{ paddingRight: 5, paddingLeft: 5 }}
                                  >
                                    <div
                                      className="row"
                                      style={
                                        this.state.isMobile
                                          ? style.buttonActionm
                                          : style.buttonAction
                                      }
                                    >
                                      <a
                                        title="Simpan"
                                        className="btn btn-success btn-fill"
                                        href="#"
                                        style={
                                          this.state.isMobile
                                            ? style.buttonModalm
                                            : {}
                                        }
                                        onClick={() =>
                                          this.saveImage(this.state.imageName)
                                        }
                                      >
                                        Simpan
                                      </a>
                                      <a
                                        title="Download"
                                        className="btn btn-info btn-fill"
                                        style={
                                          this.state.isMobile
                                            ? style.buttonModalm
                                            : { marginLeft: 15 }
                                        }
                                        href="#"
                                        onClick={() =>
                                          this.downloadImage(
                                            this.state.imageName
                                          )
                                        }
                                      >
                                        Download
                                      </a>
                                      <a
                                        title="Keluar"
                                        className="btn btn-danger btn-fill"
                                        style={
                                          this.state.isMobile
                                            ? style.buttonModalm
                                            : { marginLeft: 15 }
                                        }
                                        href="#"
                                        onClick={() =>
                                          this.setState({
                                            showModal: false,
                                          })
                                        }
                                      >
                                        Keluar
                                      </a>
                                    </div>
                                    {this.state.isMobile && (
                                      <div
                                        className="row"
                                        style={style.buttonBrightness}
                                      >
                                        <a
                                          title="Brightness"
                                          className="btn btn-secondary btn-fill"
                                          style={{
                                            borderWidth: 0,
                                            padding: "5px 13px",
                                          }}
                                          href="#"
                                        >
                                          Brightness
                                        </a>
                                        <a
                                          title="down"
                                          className="btn btn-warning btn-fill"
                                          style={{
                                            marginLeft: 5,
                                            borderWidth: 0,
                                            padding: "5px 13px",
                                          }}
                                          href="#"
                                          onClick={() =>
                                            this.brightnessImage("down", 10)
                                          }
                                        >
                                          -
                                        </a>
                                        <a
                                          title="up"
                                          className="btn btn-primary btn-fill"
                                          style={{
                                            marginLeft: 5,
                                            borderWidth: 0,
                                            padding: "5px 13px",
                                          }}
                                          href="#"
                                          onClick={() =>
                                            this.brightnessImage("up", 10)
                                          }
                                        >
                                          +
                                        </a>
                                      </div>
                                    )}
                                    <ImageEditor
                                      ref={this.editorRef}
                                      includeUI={{
                                        loadImage: {
                                          path:
                                            APP_URL_UPLOADS +
                                            this.state.imageName,
                                          name: this.state.imageName,
                                        },
                                        theme: myTheme,
                                        menu: [
                                          "filter",
                                          "crop",
                                          "flip",
                                          "rotate",
                                          "text",
                                        ],
                                        initMenu: "filter",
                                        uiSize: {
                                          width: "100%",
                                          height: "650px",
                                        },
                                        menuBarPosition: this.state.isMobile
                                          ? "bottom"
                                          : "left",
                                      }}
                                      cssMaxHeight={
                                        this.state.isMobile
                                          ? window.innerHeight
                                          : 500
                                      }
                                      cssMaxWidth={
                                        this.state.isMobile
                                          ? window.innerWidth - 30
                                          : 500
                                      }
                                      selectionStyle={{
                                        orderColor: "red",
                                        cornerColor: "green",
                                        cornerSize: 6,
                                        rotatingPointOffset: 100,
                                        transparentCorners: false,
                                      }}
                                      usageStatistics={false}
                                    />
                                  </div>
                                </Modal>
                                <div className="row">
                                  {currentSeatmaker.images &&
                                    currentSeatmaker.images.map(
                                      (image, index) => (
                                        <div key={index} className="col-md-4">
                                          <div
                                            className="thumbnail"
                                            style={
                                              image.published === 1
                                                ? style.borderRed
                                                : style.borderGreen
                                            }
                                          >
                                            <a
                                              className="remove-image"
                                              href="#"
                                              onClick={() => {
                                                if (
                                                  window.confirm(
                                                    "Apa Kamu Yakin Ingin Menghapus File ini?"
                                                  )
                                                )
                                                  this.deleteImage(image.id);
                                              }}
                                              style={style.removeImage}
                                            >
                                              &#215;
                                            </a>
                                            <a href="#">
                                              {image.type.startsWith(
                                                "video"
                                              ) ? (
                                                <ReactPlayer
                                                  width={"100%"}
                                                  height={"100%"}
                                                  // playing={true}
                                                  config={{
                                                    file: {
                                                      attributes: {
                                                        poster: "poster.jpg",
                                                      },
                                                    },
                                                  }}
                                                  controls
                                                  muted={true}
                                                  url={
                                                    APP_URL_UPLOADS +
                                                    image.title
                                                  }
                                                />
                                              ) : (
                                                <a
                                                  onClick={() =>
                                                    this.editImage(image.title)
                                                  }
                                                  href="#"
                                                >
                                                  <img
                                                    src={
                                                      APP_URL_UPLOADS +
                                                      image.title
                                                    }
                                                    alt="logo_image"
                                                  ></img>
                                                </a>
                                              )}
                                              {/* <a className="accept-image" href="#"  onClick={() => this.acceptImage(image.id)} style={style.acceptImage}>&#111;</a> */}
                                              <div className="caption">
                                                <p>{image.title}</p>
                                              </div>
                                            </a>
                                            <div className="flex">
                                              <textarea
                                                id="description"
                                                type="text"
                                                placeholder="Deskripsi"
                                                style={style.deskripsi}
                                                onChange={
                                                  this.onChangeDescription
                                                }
                                              >
                                                {image.description}
                                              </textarea>
                                            </div>
                                            <div className="col text-center">
                                              {image.published === 2 ? (
                                                <button
                                                  className="btn btn-success btn-fill"
                                                  onClick={() =>
                                                    this.acceptImage(
                                                      image.id,
                                                      "1"
                                                    )
                                                  }
                                                >
                                                  Sudah Disetujui
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn btn-danger btn-fill"
                                                  // href="#"
                                                  onClick={() =>
                                                    this.acceptImage(
                                                      image.id,
                                                      "2"
                                                    )
                                                  }
                                                >
                                                  Belum Disetujui
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>

                              {/* <div className="form-group">
                              <label>
                                <strong>Status:</strong>
                              </label>
                              {currentSeatmaker.published ? " Disetujui" : " Belum Disetujui"}
                            </div> */}
                            </form>
                            <p>{this.state.message}</p>
                          </div>
                        ) : (
                          <div>
                            <br />
                            <p>Please click on a Seatmaker...</p>
                          </div>
                        )}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
const style = {
  title: {
    textAlign: "center",
    fontFamily: "Helvetica",
    marginTop: 50,
  },
  borderRed: {
    border: "3px solid red",
  },
  borderGreen: {
    border: "3px solid #7DCE13",
  },
  preview: {
    display: "flex",
    flexWrap: "nowrap",
    maxWidth: "25%",
    padding: "0 4px",
  },
  previewImage: {
    marginTop: "8px",
    // verticalAlign: middle,
    width: "80%",
  },
  removeImage: {
    display: "inline",
    position: "absolute",
    top: "-10px",
    right: "0px",
    borderRadius: "10em",
    padding: "2px 6px 3px",
    textDecoration: "none",
    font: "700 21px/20px sans-serif",
    background: "	#f42107",
    border: "3px solid #fff",
    color: "#FFF",
    boxShadow: "0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3)",
    textShadow: "0 1px 2px rgba(0,0,0,0.5)",
    WebkitTransition: "background 0.5s",
    transition: "background 0.5s",
  },
  deskripsi: {
    width: "100%",
    height: "100px",
  },
  modal: {
    marginLeft: "auto",
    marginRight: "auto",
    left: "-35%",
  },
  modalm: {
    marginLeft: "auto",
    marginRight: "auto",
    // left: "-35%",
  },
  buttonAction: {
    position: "absolute",
    zIndex: 3,
    top: "2%",
    left: "60%",
    width: "100%",
    margin: "0 auto",
  },
  buttonActionm: {
    position: "absolute",
    margin: "0 auto",
    zIndex: 3,
    top: "1%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonBrightness: {
    position: "absolute",
    margin: "0 auto",
    zIndex: 3,
    top: "6%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonModalm: {
    marginLeft: 5,
    borderWidth: 0,
    padding: "5px 13px",
  },
};
