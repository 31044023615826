import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './autocomplete.css';

export class Autocomplete extends Component {
  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired
  };
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: ''
  };

  onChange = (e) => {
    console.log('onChanges');

    const { options } = this.props;
    const userInput = e.currentTarget.value;

    const filteredOptions = options.filter(
      (optionName) =>
        optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
  };
  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: filteredOptions[activeOption]
      });
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        console.log(activeOption);
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,

      state: { activeOption, filteredOptions, showOptions, userInput }
    } = this;
    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
          <ul className="options">
            {filteredOptions.map((optionName, index) => {
              let className;
              if (index === activeOption) {
                className = 'option-active';
              }
              return (
                <li className={className} key={optionName} onClick={onClick}>
                  {optionName}
                </li>
              );
            })}
          </ul>
        );
      } else {
        optionList = (
          <div className="no-options">
            <em>No Option!</em>
          </div>
        );
      }
    }
    return (
      <React.Fragment>
        <div className="search">
          <input
            id="toko"
            type="text"
            className="search-box"
            onChange={onChange}
            onKeyDown={onKeyDown}
            defaultValue={userInput}
          />
          {/* <input type="submit" value="" className="search-btn" /> */}
        </div>
        {optionList}
      </React.Fragment>
    );
  }
}

export default Autocomplete;



































// import React, { Component } from 'react';
// import PropTypes from 'prop-types';

// import './autocomplete.css';

// export class Autocomplete extends Component {
//   static propTypes = {
//     options: PropTypes.instanceOf(Array).isRequired
//   };
//   state = {
//     activeOption: 0,
//     filteredOptions: [],
//     showOptions: false,
//     userInput: '',
//     namaToko: [],
//     page:"",
//     tampunganIdToko:[],
//   };

//   componentDidMount(){
//   }
  
//   namaToko = (toko) =>{
//       var tempToko = [];
//       var tempId = []
//       let i = 0;
//       const urlFetch = fetch(`https://mbtech.app/api/smaker/?toko=${toko}`);
//       urlFetch.then(res => {
//         if(res.status === 200)
//           return res.json()
//       })
//       .then(resJson => {
//         for(i=0; i < resJson.seatmakers.length; i++){
//           tempToko.push(
//             resJson.seatmakers[i].toko
//           )
//           tempId.push(
//             {
//               id: resJson.seatmakers[i].id,
//               toko: resJson.seatmakers[i].toko,
//             }
//           )
//             i = i++;
//         }
//         console.log('nilai i+1:',i);
//         console.log('nilai i:',tempToko);
//         console.log('nilai toko id:',tempId);
//         this.setState({
//           page: resJson.seatmakers,
//           filteredOptions: tempToko,
//           tampunganIdToko: tempId
//         })
//       })
    
//   }

//   onChange = (e) => {
//     console.log('onChanges');
    
//     const { options } = this.props;
//     const userInput = e.currentTarget.value;
    
//     const filteredOptions = options.filter(
//       (optionName) =>
//         optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
//     );
    
//     this.namaToko(userInput);
//     // console.log('filteredOptions',filteredOptions);
//     this.setState({
//       activeOption: 0,
//       filteredOptions,
//       showOptions: true,
//       userInput: e.currentTarget.value
//     });
//   };

//   onClick = (e) => {
//     this.setState({
//       activeOption: 0,
//       filteredOptions: [],
//       showOptions: false,
//       userInput: e.currentTarget.innerText
//     });
//   };
//   onKeyDown = (e) => {
//     const { activeOption, filteredOptions } = this.state;

//     if (e.keyCode === 13) {
//       this.setState({
//         activeOption: 0,
//         showOptions: false,
//         userInput: filteredOptions[activeOption]
//       });
//     } else if (e.keyCode === 38) {
//       if (activeOption === 0) {
//         return;
//       }
//       this.setState({ activeOption: activeOption - 1 });
//     } else if (e.keyCode === 40) {
//       if (activeOption === filteredOptions.length - 1) {
//         console.log(activeOption);
//         return;
//       }
//       this.setState({ activeOption: activeOption + 1 });
//     }
//   };

//   render() {
//     // console.log(this.state.page)
//     const {
//       onChange,
//       onClick,
//       onKeyDown,

//       state: { activeOption, filteredOptions, showOptions, userInput,}
//     } = this;
//     let optionList;
//     if (showOptions && userInput) {
//       if (filteredOptions.length) {
        
//         optionList = (
//           <ul className="options">
//             {filteredOptions.map((optionName, index) => {
//               let className;
//               if (index === activeOption) {
//                 className = 'option-active';
//               }
//               return (
//                 <li className={className} key={optionName} onClick={onClick}>
//                   {optionName}
//                 </li>
//               );
//             })}
//           </ul>
//         );
//       } else {
//         optionList = (
//           <div className="no-options">
//             <em>No Option!</em>
//           </div>
//         );
//       }
//     }
//     return (
//       <React.Fragment>
//         <div className="search">
//           <input
//             type="text"
//             className="search-box"
//             onChange={onChange}
//             onKeyDown={onKeyDown}
//             value={userInput}
//           />
//           <input 
//             // type="submit" 
//             // value="" 
//             disabled
//             className="search-btn" 
//           />
//         </div>
//         {optionList}
//       </React.Fragment>
//     );
//   }
// }

// export default Autocomplete;
