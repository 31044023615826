import http from "./services.http"
// import axios from "axios";

class ServicesPTS{
    

    // SELURUH PESANAN
    // SELURUH PESANAN
        getAllOrder(params){
            return http.get("/order/all", { params });
        }
    // SELURUH PESANAN
    // SELURUH PESANAN

    // START Order Shop Banner
    getAllPts(){
        return http.get("/order");
    }

    getAllOrderPts(params){
        return http.get("/order", { params });
    }

    getSearchOrderBannerId(id){
        return http.get(`/order/detailBanner/${id}`);
    }

    createOrderBanner(data){
        let formdata = new FormData();
        formdata.append("dataProId", data.dataProId);
        formdata.append("type", data.type);
        formdata.append("size", data.size);
        formdata.append("files", data.files);

        formdata.append("display_name", data.display_name);
        formdata.append("display_tagline", data.display_tagline);
        formdata.append("display_contact", data.display_contact);
        formdata.append("isName", data.isName);
        formdata.append("isTagline", data.isTagline);
        formdata.append("isContact", data.isContact);

        formdata.append("recipient", data.recipient);
        formdata.append("delivery_address", data.delivery_address);
        formdata.append("phone", data.phone);
    
        return http.post("/order/saveBanner", formdata,{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    updateOrderBannerId(id, data){
        return http.put(`/order/updateBanner/${id}`,data);
    }

    deleteOrderBannerId(id){
        return http.delete(`/order/deleteBanner/${id}`);
    }
    // END Order Shop Banner


    // START Master Sample
    getDataMasterSample(params){
        return http.get("/order/sample", { params });
    }

    getDetailMasterSample(id){
        return http.get(`/order/detailSample/${id}`);
    }

    createMasterSample(data){
        console.log('data save:',data)
        return http.post('/order/saveSample', data);
    }

    editMasterSample(id, data){
        return http.put(`/order/updateSample/${id}`,data);
    }

    deleteMasterSampleId(id){
        return http.delete(`/order/deleteSample/${id}`);
    }
    // END Master Sample

    // START CATALOG
    getAllCatalog(params){
        return http.get("order/catalog", { params })
    }

    getDetailCatalog(id){
        return http.get(`/order/detailCatalog/${id}`);
    }

    createCatalog(data){
        return http.post('/order/saveCatalog', data);
    }

    editCatalog(id, data){
        return http.put(`/order/updateCatalog/${id}`,data);
    }

    deleteCatalog(id){
        return http.delete(`/order/deleteCatalog/${id}`);
    }
    // END CATALOG

    // START Rubbel Label
    getAllLabel(params){
        return http.get("order/label",{ params });
    }

    getDetailLabel(id){
        return http.get(`/order/detailLabel/${id}`);
    }

    createLabel(data){
        return http.post('/order/saveLabel', data);
    }

    editLabel(id, data){
        return http.put(`/order/updateLabel/${id}`,data);
    }

    deleteLabel(id){
        return http.delete(`/order/deleteLabel/${id}`);
    }
    // END Rubber Label

    // TOKO/SEATMAKER
    
    // TOKO/SEATMAKER
}

export default new ServicesPTS();