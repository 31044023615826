import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import servicesPTS from "../../../Services/ServicesPTS";

import Autocomplete from "../../../Components/autoComplate/AutoComplete";

import SeatmakerDataService from "../../../../../services/smaker.service";
import { param } from "jquery";

const LabelInput = () =>{
    const viewLabel = process.env.REACT_APP_APP_URL+'tool-support/label';
    const history = useHistory();

    const [dataMS, setDataMS] = useState([]);
    const [insertMS, setInsertMS] = useState({});
    const [dataProId, setDataProId] = useState("");
    const [qty, setQty] = useState("");
    const [noReceipt, setNoReceipt] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [penjumlahan, setPenjumlahan] = useState(0);

    const [seatmakers, setSeatmakers] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState("");
    const [totalItems2, setTotalItems2] = useState();

    const [isLoading, setIsloading] = useState(false);

    // AutoComplate
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [input, setInput] = useState("");
    const [tempAddress, setTempAddress] = useState("");

    useEffect(() =>{
        
    },[]);



    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["toko"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    }



    const retrieveSeatmakers = async (item) => {
        var temp = [];
        const items  = item;
        setIsloading(true);
        if(items.length >= 3){
            const params = getRequestParams(items, page, pageSize);
            const response = await SeatmakerDataService.getAll(params);
            const itemTotal = await items ? response.data.totalItems : '';
            const paramsEks = getRequestParams(items, page, itemTotal);
            const responsFin = await (await SeatmakerDataService.getAll(paramsEks)).data.seatmakers;
            for(let i=0; i < responsFin.length; i++){
                temp.push({
                    id: responsFin[i].id,
                    toko: responsFin[i].toko
                });
            }
            return temp;
        }else{
            setTempAddress("");
            setName("");
            setAddress("");
            setPhone("");
            return temp;
        }
    }
    
    const filterNameShop = async(namaToko) => {
        var temp = namaToko;
        var result = temp.substring(0,temp.indexOf(" - "));
        
        var responProfile = await(await SeatmakerDataService.getProfile(result)).data;

        setTempAddress(responProfile);
        setName(responProfile.contact);
        setAddress(responProfile.alamat);
        setPhone(responProfile.phone);   
    }




    const onChange = async(e) => {
        e.persist();
        const input = e.currentTarget.value;
        let todoList = await retrieveSeatmakers(input);
        let listToko = todoList.map((data) => data.id+' - '+data.toko);
        
        const suggestions  = listToko;
        
        console.log('sebelum filter',suggestions)
        const newFilteredSuggestions = suggestions.filter(
            suggestion =>
            suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
        );
        console.log('filter ',newFilteredSuggestions);
        setIsloading(false);
        setActive(0);
        setFiltered(newFilteredSuggestions);
        setIsShow(true);
        console.log('uian',newFilteredSuggestions);
        setInput(input);
        
    };

    const onClick = e => {
        
        setActive(0);
        setFiltered([]);
        setIsShow(false);
        setInput(e.currentTarget.innerText);
        filterNameShop(e.currentTarget.innerText);
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) { // enter key
          setActive(0);
          setIsShow(false);
          setInput(filtered[active]);
          filterNameShop(filtered[active]);
          e.preventDefault();
        }
        else if (e.keyCode === 38) { // up arrow
          return (active === 0) ? null : setActive(active - 1);
        }
        else if (e.keyCode === 40) { // down arrow
          return (active - 1 === filtered.length) ? null : setActive(active + 1);
        }
    };

    const renderAutocomplete = () => {
        if (isShow && input) {
            // console.log('render',input);
          if (filtered.length) {
            return (
              <ul className="autocomplete">
                {
                    isLoading ? 
                        <>
                            <li className="lazyColor">Loading...</li>
                            <li className="lazyColor">...</li>
                            <li className="lazyColor">...</li>
                        </>
                    :
                    filtered.map((suggestion, index) => {
                        let className;
                        if (index === active) {
                            className = "active";
                        }
                        return (
                            <li className={className} key={suggestion} onClick={onClick}>
                            {suggestion}
                            </li>
                        );
                    })
                }
              </ul>
            );
          } else {
            return (
              <div className="no-autocomplete">
                <em>Not found</em>
              </div>
            );
          }
        }
        return <></>;
    }

    // const []

    
    


    //     const onChangeSearchTitle = (e) => {
    //         const searchTitle = e.target.value;
        
    //         setSearchTitle(searchTitle);
    //         retrieveSeatmakers(searchTitle);
    //         console.log('seatmakers',seatmakers);

    //     }
    
    //   const getRequestParams = (searchTitle, page, pageSize) => {
    //     let params = {};
    
    //     if (searchTitle) {
    //       params["toko"] = searchTitle;
    //     }
    
    //     if (page) {
    //       params["page"] = page - 1;
    //     }
    
    //     if (pageSize) {
    //       params["size"] = pageSize;
    //     }
    
    //     return params;
    //   }
    
    // const retrieveSeatmakers = (item) => {
    //     // const { searchTitle, page, pageSize }
    //     const params = getRequestParams(item, page, pageSize);
    //     console.log('params', params);

    //     SeatmakerDataService.getAll(params)
    //         .then((response) => {
    //             const { totalPages } = response.data;
    //             setTotalItems(response.data.totalItems);
    //             setSeatmakers(response.data);
    //             setCount(totalPages);
    //     })
    //     .catch((e) => {
    //         console.log(e);
    //     });

    //     const newParams = getRequestParams(item, page, totalItems);
        
    //     SeatmakerDataService.getAll(newParams)
    //         .then((response) => {
    //             const { totalPages } = response.data;
    //             setSeatmakers(response.data);
    //             setCount(totalPages);
    //     })
    //     .catch((e) => {
    //         console.log(e);
    //     });
        
    // }
    
    
    // console.log('count', count);
    
    
    // console.log(dataProId);
    const targetQty = (e) => {
        setQty(e.target.value);
    }

    const targetPhone = (e) =>{
        setPhone(e.target.value);
    }

    const targetAddress = (e) => {
        setAddress(e.target.value);
    }

    const targetName = (e) => {
        setName(e.target.value);
    }

    const targetNoReceipt = (e) =>{
        setNoReceipt(e.target.value);
    }

    const onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const saveRubberLabel = (e) => {
        e.preventDefault();
        var inputToko = input.split(' ');

        var data = {
            dataProId : inputToko[0],
            qty: qty,
            // sendTo : sendTo,
            noReceipt : noReceipt,
            name : name,
            address : address,
            phone : phone,
            
        }
        
        console.log(data);
        servicesPTS.createLabel(data)
        .then(response =>{
            // this.setState({
            //     dataProId: response.data.dataProId,
            //     qty: response.data.qty,
            //     // sendTo: response.data.sendTo,
            //     noReceipt: response.data.noReceipt,
            //     name: response.data.name,
            //     address: response.data.address,
            //     phone: response.data.phone,
            // });

            if(response.data.success == true){
                history.push({
                    pathname: '/tool-support/label',
                    state:{
                        kirim: 'data berhasil disimpan',
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
        });
    }
    return(
        <div className="row">
            <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20 mb-17per">
                <div className="" id="breadcrumb">
                    <div className="padding-h15">
                        <a href={viewLabel}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className="span-breadcrumb pl-15">Rubber Label</span>
                        </a>
                        {/* <span className="span-breadcrumb pl-15">/ Input Master Sample </span> */}
                    </div>
                </div>
                <div className="row pl-20 color-1 fontFamily">
                    <div className="col-lg-12 pl-0" id="title-h1">
                        <h1>Input Rubber Label</h1>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                        <div className="col-lg-12 pl-0">
                            <h3><strong>Request Rubber Label</strong></h3>
                        </div>
                            <form className="col-lg-12" method="POST" encType="multipart/form-data" ref={form => form = form}>
                                
                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="qty">Nama Toko <span style={{color:'red'}}>*(3 Huruf)</span></label>
                                        </div>
                                        <div className="col-lg-10 d-flex">
                                            <div className="bg-jumlah col-lg-6 col-md-6 col-xs-12" for="toko">
                                                <input
                                                    autoComplete="off"
                                                    className="button-typeText text-effect col-lg-12 col-xs-12"
                                                    type="text"
                                                    id="seatmaker"
                                                    onChange={(e) => { onChange(e)}}
                                                    onKeyDown={onKeyDown}
                                                    value={input}
                                                    placeholder="Nama Toko / Seatmaker"
                                                />
                                                {renderAutocomplete()}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                

                                <div class="form-group">
                                    <div className="row">
                                        <div className="col-lg-2 pl-0">
                                            <label className="font-w400 fontFamily" for="qty">Rubber Label <span style={{color:'red'}}>*</span></label>
                                        </div>
                                        <div className="col-lg-10 col-xs-12 d-flex">
                                            <div className="bg-jumlah col-lg-5 col-md-5 col-xs-10" for="qty">
                                                <label for="qty" className="col-lg-3 col-xs-3 pl-0 pt-10">Jumlah</label>
                                                <input 
                                                    type="number" 
                                                    min={0}
                                                    value={qty ? qty : 0}
                                                    onChange={targetQty} 
                                                    id="qty" 
                                                    className="button-typeText col-lg-9 text-effect col-xs-9"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div className="row" id="teksBanner">
                                        <div className="col-lg-12 pl-0">
                                            <h3>Dikirim Kepada</h3>
                                        </div>
                                        <div className="container-fluid">
                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="noReceipt"  
                                                            value={noReceipt}
                                                            onChange={targetNoReceipt} 
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            key={tempAddress.contact}
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="name"  
                                                            defaultValue={tempAddress.contact} 
                                                            onChange={targetName} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            key={tempAddress.alamat}
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            name="address"  
                                                            defaultValue={tempAddress.alamat}
                                                            onChange={targetAddress} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div className="row">
                                                    <div className="col-lg-2 pl-0 font-w100">
                                                        <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                    </div>
                                                    <div className="col-lg-10 d-flex">
                                                        <input 
                                                            maxLength={12}
                                                            key={tempAddress.phone}
                                                            type="text" 
                                                            className="button-typeText" 
                                                            id="formUkuran2" 
                                                            onKeyPress={onKeyNumber} 
                                                            name="phone"
                                                            defaultValue={tempAddress.phone} 
                                                            onChange={targetPhone} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-10">
                                    <button onClick={(e) => saveRubberLabel(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Kirim Permintaan</button>
                                </div>
                            </form>
                        </div>

                            

                    </div>
                </div>
                
            </div>
            
        </div>
    );
}
export default LabelInput;