import { toHaveFormValues } from "@testing-library/jest-dom";
import React, { Component } from "react";
import { Modal,Button,} from "react-bootstrap";

import "../../../../assets/css/PTS/inputMasterSample.css";
import "../../../../assets/css/PTS/catalog.css";



import SeatmakerDataService from "../../../../services/smaker.service";
import servicesPTS from "../../ServicesPTS/ServicesPTS";

// image



class InputCatalog extends Component {
    
    constructor(props) {
        super(props);        
        this.state = {
            dataMS: [],
            insertMS:{
            },

            id: 0,
            dataProId: 209,
            sendTo: "",
            products:[
                {
                    "varian": "Camaro",
                    "total": 0,
                    "color": "",
                },
                {
                    "varian": "Camaro Fiesta",
                    "total": 0,
                    "color": "",
                },
                {
                    "varian": "Giorgio",
                    "total": 0,
                    "color": "",
                },
                {
                    "varian": "New Superior",
                    "total": 0,
                    "color": "",
                },
                {
                    "varian": "Picasso",
                    "total": 0,
                    "color": "",
                },
                {
                    "varian": "Premium Carrera",
                    "total": 0,
                    "color": "",
                },
                {
                    "varian": "Rider Cruiser",
                    "total": 0,
                    "color": "",
                },
                {
                    "varian": "Catalog Book",
                    "total": "",
                    "color": "",
                },
            ],
            
            noReceipt: "",
            name: "",
            address: "",
            phone: "",

            varian: "",
            size: "",
            total: 0,
            color: "",

            penjumlahan: 0,
            
        };
        
        this.onChangeProducts = this.onChangeProducts.bind(this);
        this.funcAddition = this.funcAddition.bind(this);

        this.targetPhone = this.targetPhone.bind(this);
        this.targetAddress = this.targetAddress.bind(this);
        this.targetName = this.targetName.bind(this);
        this.targetNoReceipt = this.targetNoReceipt.bind(this);
        this.saveCatalog = this.saveCatalog.bind(this);
        
    }

    componentDidMount() {  
        // this.saveMS = this.saveMS.bind(this);
    }
    
    
    onChangeProducts(e){
        const products = this.state.products;
        const val = e.target.value;
        var id = e.target.id;
        if(id === 'Catalog Book'){
            products.push(
                {
                    varian: id,
                    total: 0,
                    color: val,
                },
            )
        }else{
            products.push(
                {
                    varian: id,
                    total: val,
                    color: '',
                },
            )
        }
        
        // this.funcAddition();
    }

    targetPhone(e){
        this.setState(
            {
                phone: e.target.value
            },
            // ()=>{console.log(this.state.phone);}
        )
    }
    targetAddress(e){
        this.setState(
            {
                address: e.target.value
            },
        )
    }
    targetName(e){
        this.setState(
            {
                name: e.target.value
            },
        )
    }
    targetNoReceipt(e){
        this.setState(
            {
                noReceipt: e.target.value
            },
        )
    }
    

    
    onKeyNumber = event =>{
        if (!/[0-9.;,]/.test(event.key)) {
            event.preventDefault();
        }
    }

    funcAddition(){
        const temp_products = [...new Map(this.state.products.map(o => [o.varian, o])).values()];
        let jumlahnya = 0;
        for(let i = 0; i < temp_products.length; i++){
            jumlahnya += Number(temp_products[i].total);
        }
        this.setState(
            {
                penjumlahan : jumlahnya
            }
        )
    }

    
    saveCatalog(e){
        e.preventDefault();
        const products = [...new Map(this.state.products.map(o => [o.varian, o])).values()]

        var data = {
            dataProId : this.state.dataProId,
            sendTo :this.state.sendTo,
            noReceipt : this.state.noReceipt,
            name : this.state.name,
            address : this.state.address,
            phone : this.state.phone,
            product: products,
        }
        servicesPTS.createCatalog(data)
        .then(response =>{
            this.setState({
                dataProId: response.data.dataProId,
                sendTo: response.data.sendTo,
                noReceipt: response.data.noReceipt,
                name: response.data.name,
                address: response.data.address,
                phone: response.data.phone,
                product: response.data.product
            });

            if(response.data.success == true){
                this.props.history.push({
                    pathname: '/tool-support/catalog',
                    state:{
                        kirim: 'data berhasil disimpan',
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
        });
    }
    // saveMS(e){
    //     e.preventDefault();
    //     const products = [...new Map(this.state.products.map(o => [o.varian, o])).values()]

    //     var data = {
    //         dataProId : this.state.dataProId,
    //         sendTo :this.state.sendTo,
    //         noReceipt : this.state.noReceipt,
    //         name : this.state.name,
    //         address : this.state.address,
    //         phone : this.state.phone,
    //         product: products,
    //     }
    //     console.log('asss', data);

    //     servicesPTS.createMasterSample(data)

    //     .then(response => {
    //         this.setState({
    //             dataProId: response.data.dataProId,
    //             sendTo: response.data.sendTo,
    //             noReceipt: response.data.noReceipt,
    //             name: response.data.name,
    //             address: response.data.address,
    //             phone: response.data.phone,
    //             product: response.data.product
    //         })
    //     })
        
        
        // servicesPTS.createOrderBanner(data)
        // .then(response => {
            
        //     this.setState({
        //         dataProId: response.data.dataProId,
        //         type: response.data.jenisShopBanner,
        //         size: response.data.size,
        //         files: response.data.files,
                
        //         display_name: response.data.display_name,
        //         display_tagline: response.data.display_tagline,
        //         display_contact: response.data.display_contact,
        //         isName: response.data.isName,
        //         isTagline: response.data.isTagline,
        //         isContact: response.data.isContact,
                
        //         delivery_address: response.data.delivery_address,
        //         recipient: response.data.recipient,
        //         phone: response.data.phone,
        //     });
            
        //     if(response.data.success == true){
        //         console.log(response.data.success);
        //         this.props.history.push({
        //             pathname: '/tool-support/order',
        //             state :{
        //                 id: this.state.dataProId
        //             },
        //         },
        //         // ()=>{console.log(this.state.id);}
        //         );
        //         this.setState({
        //             dataProId : this.state.dataProId,
        //         },
        //         // ()=>{console.log(this.state.dataProId);}
        //         );
                
        //     }
            
        // })
        // .catch(e => {
        //     console.log(e);
        // });
        
        
    // }

    render() {
        const {
            id,
            total,
            products
        } = this.state;
        return (
            
            <div className="row">
                <div className="col-lg-12 col-md-12 box-shadow-1 border-radius-10 mt-pto-1 border-left-20">
                    <div className="" id="breadcrumb">
                        <div className="padding-h15">
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#C49E17" stroke-width="2"/>
                                    <path d="M13.5 8L9.5 12L13.5 16" stroke="#C49E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <span className="span-breadcrumb pl-15">Catalog</span>
                            {/* <span className="span-breadcrumb pl-15">/ Input Master Sample </span> */}
                        </div>
                    </div>
                    <div className="row pl-40 color-1 fontFamily">
                        <div className="col-lg-12 pl-0" id="title-h1">
                            <h1>Input Catalog</h1>
                        </div>
                        <div className="col-lg-12">
                            <div className="row">
                            <div className="col-lg-12 pl-0">
                                <h3><strong>Request Catalog</strong></h3>
                            </div>
                                <form className="col-lg-12" method="POST" encType="multipart/form-data" ref={form => this.form = form}>
                                    
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="Camaro">Camaro</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="jumlahCamaro">
                                                    <label for="Camaro">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts} 
                                                        id="Camaro" 
                                                        className="ml-15 button-typeText text-effect"
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="Camaro Fiesta">Camaro Fiesta</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="Camaro Fiesta">
                                                    <label for="Camaro Fiesta">Jumlah</label>
                                                    <input 
                                                        type="number"
                                                        min={0}
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts} 
                                                        id="Camaro Fiesta" 
                                                        className="ml-15 button-typeText text-effect"
                                                    />
                                                    {/* <span>
                                                        <input 
                                                            type="checkbox" 
                                                            id="vehicle2" 
                                                            name="vehicle2" 
                                                            value="checklistCamaro"
                                                        />
                                                    </span> */}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="Giorgio">Giorgio</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="Giorgio">
                                                    <label for="Giorgio">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Giorgio" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="New Superior">New Superior</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="New Superior">
                                                    <label for="New Superior">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0} 
                                                        id="New Superior" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="Picasso">Picasso</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="Picasso">
                                                    <label for="Picasso">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Picasso" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="Premium Carrera">Premium Carrera</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="Premium Carrera">
                                                    <label for="Premium Carrera">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Premium Carrera" 
                                                        className="ml-15 button-typeText text-effect"
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        onChange={this.onChangeProducts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="Rider Cruiser">Riders Cruiser</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah " for="Rider Cruiser">
                                                    <label for="Rider Cruiser">Jumlah</label>
                                                    <input 
                                                        type="number" 
                                                        min={0}
                                                        id="Rider Cruiser" 
                                                        defaultValue={this.state.products.total ? this.state.products.total : 0} 
                                                        className="ml-15 button-typeText text-effect"
                                                        onChange={this.onChangeProducts}
                                                        
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily" for="Catalog Book">Catalog Book (mix)</label>
                                            </div>
                                            <div className="col-lg-10 d-flex">
                                                <div className="bg-jumlah d-flex" for="Catalog Book">
                                                    <textarea 
                                                        id="Catalog Book"  
                                                        className="" 
                                                        rows="4" 
                                                        cols="29"
                                                        value={this.state.products.total}
                                                        onChange={this.onChangeProducts}
                                                        placeholder="Catalog Campuran / Bundle"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    {/* <div class="form-group">
                                        <div className="row">
                                            <div className="col-lg-2 pl-0">
                                                <label className="font-w400 fontFamily">Jumlah Total</label>
                                            </div>
                                            <div className="col-lg-10 d-flex pl-0">
                                                <input 
                                                    value={this.state.penjumlahan}
                                                    disabled 
                                                    id="jumlahcustom" 
                                                    className="ml-15 button-typeText text-effect"
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div class="form-group">
                                        <div className="row" id="teksBanner">
                                            <div className="col-lg-12 pl-0">
                                                <h3>Dikirim Kepada</h3>
                                            </div>
                                            <div className="container-fluid">
                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">No.Resi Ekspedisi</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="noReceipt"  
                                                                value={this.state.noReceipt}
                                                                onChange={this.targetNoReceipt} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Nama</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="name"  
                                                                value={this.state.name} 
                                                                onChange={this.targetName} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Alamat</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                name="address"  
                                                                value={this.state.address}
                                                                onChange={this.targetAddress} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-2 pl-0 font-w100">
                                                            <label className="font-w400 fontFamily" for="ukuranShopCustom">Kontak</label>
                                                        </div>
                                                        <div className="col-lg-10 d-flex">
                                                            <input 
                                                                type="text" 
                                                                className="button-typeText" 
                                                                id="formUkuran2" 
                                                                onKeyPress={this.onKeyNumber} 
                                                                name="phone"
                                                                value={this.state.phone} 
                                                                onChange={this.targetPhone} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <button onClick={(e) => this.saveCatalog(e)} className="buttonSubmitKirim d-grid mx-auto" type="submit">Kirim Permintaan</button>
                                    </div>
                                </form>
                            </div>

                                

                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default InputCatalog;